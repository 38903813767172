import React from 'react';
import AddServiceCard from './add-services-card';
import ProviderSegment from '../ui/provider-segment';

const Creds = (props) => {
    return (
        <div style={ { marginTop: '20px' } }>
            <AddServiceCard
                onChange={ props.onChange }
                address={ props.address }
                creds={ props.creds }
                storeId={ props.storeId }
            />
            { 
                props.creds && 
                <div className="saved-cred-cards">
                    {
                        [ ...props.creds ].reverse().map((cred, idx) => {
                            const providerMatch = props.providers[cred.provider];
                            return (
                                <ProviderSegment 
                                    key={ idx }
                                    provider={ providerMatch ? providerMatch.display_name : cred.provider }
                                />
                            );
                        })
                    }
                </div>
            }
        </div>
    )
}

export default Creds;
