import React, { useState } from 'react';
import { Form } from 'semantic-ui-react';

const FormField = (props) => {
    const [validationMessage, setValidationMessage] = useState(null);
    const [touched, setTouched] = useState(false);

    const normalize = (value) => {
        return props.normalize ? props.normalize(value) : value;
    }

    const validate = (value) => {
        if (props.validate) {
            const validationMessage = 
                Array.isArray(props.validate) ?
                props.validate.map(func => func(value)).filter(el => el).join('. ')
                : props.validate(value);
            setValidationMessage(validationMessage);
            if (validationMessage) {
                return false;
            }
        }
        return true;
    }

    const onChange = (e, touch = true) => {
        const { name } = e.target;
        const value = normalize(e.target.value)
        const valid = validate(value);
        props.onChange({ 
            [name]: {
                value: value || '',
                valid
            }
        });
        setTouched(touch);
    }

    return (
        <Form.Field width={ props.width } error={ validationMessage && touched ? true : false } style={ props.style }>
            <label>{ props.label }</label>
            <input 
                autoComplete="off"
                type={ props.type || 'text' }
                placeholder={ props.placeholder } 
                onChange={ onChange }
                onBlur={ onChange }
                onFocus={ props.onFocus }
                name={ props.name } 
                value={ props.value || '' } 
                maxLength={ props.maxLength }
            />
            {
                validationMessage
                && touched 
                && <div className="error-text">
                    { validationMessage }
                </div>
            }
        </Form.Field>
    )
};

export default FormField;
