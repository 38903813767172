import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

import Error from './error';
import Page from '../modules/page';
import Billing from '../modules/billing';
import MicroDepositVerify from '../modules/billing/micro-deposit-verify';
import Company from '../modules/company';
import Locations from '../modules/locations';
import Location from '../modules/location';
import Thankyou from '../modules/thank-you';
import Help from '../modules/help';

const app_id = localStorage.getItem("app_id");
let opportunity_id = localStorage.getItem("opportunity_id");

const Routes = (props) => {
    let start_paths = ["/start/*", "/start/:app_id", "/company/*", "/company/:app_id", "/payments/:app_id"];
    if (
      !localStorage.getItem("app_id") &&
      start_paths.includes(props.match.path)
    ) {
      window.localStorage.setItem("app_id", props.match.params["app_id"]);
    }

    useEffect(() => {
      const initialLoad = () => {
        ReactGA.pageview(window.location.pathname);
        if (app_id) {
          ReactGA.set({ userId: app_id });
        }
  
        // redirect them to the new unified routes
        if (window.location.pathname.includes("/multi")) {
          window.location.href = window.location.href.replace("/multi", "");
        }
      };
      initialLoad();
    }, []);

    if (window.location.pathname.includes("/multi")) {
      return null;
    }

    return (
      <Switch>
        <Route
          exact
          path="/start/:app_id"
          render={(unused) => <Redirect to={`/company/${app_id}`} />}
        />
        <Route
          exact
          path="/start/*"
          render={(unused) => <Redirect to={`/company/?app_id=${app_id}&opportunity_id=${opportunity_id}`} />}
        />
        <Route
          exact
          path="/company/*"
          render={(props) => {
            return (
              <Page currentStep={1}>
                <Company {...props} />
              </Page>
            );
          }}
        />
        <Route
          exact
          path="/payments/:app_id"
          render={(props) => {
            return (
              <Page currentStep={2}>
                <Billing {...props} />
              </Page>
            );
          }}
        />
        <Route
          exact
          path="/locations/:app_id"
          render={(props) => {
            return (
              <Page currentStep={3}>
                <Locations {...props} />
              </Page>
            );
          }}
        />
        <Route
          exact
          path="/locations/:app_id/store/new"
          render={(props) => {
            return (
              <Page currentStep={3}>
                <Location {...props} />
              </Page>
            );
          }}
        />
        <Route
          exact
          path="/locations/:app_id/store/:store_id"
          render={(props) => {
            return (
              <Page currentStep={3}>
                <Location {...props} />
              </Page>
            );
          }}
        />
        <Route
          exact
          path="/verify/:app_id"
          render={(props) => {
            return (
              <Page>
                <MicroDepositVerify {...props} />
              </Page>
            );
          }}
        />
        <Route
          exact
          path="/thankyou/:app_id"
          render={(props) => (
            <Page>
              <Thankyou {...props} />
            </Page>
          )}
        />
        <Route
          path="/help/:app_id"
          render={(props) => (
            <Page>
              <Help {...props} />
            </Page>
          )}
        />
        <Route component={Error} />
      </Switch>
    )
};

export default Routes;
