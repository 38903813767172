import React from 'react'
import FormField from '../form-field';
import FormSelect from '../form-select';
import { Form } from 'semantic-ui-react';
import { states, countries, canadaProvinces } from '../../../dummyData/constants';

import { required, minLength5, phoneNumber } from '../../forms/validate';
import normalizePhone from '../../forms/normalizePhone';

const CompanyAddress = (props) =>  {
    const { formValues = {}, onFocus, onChange } = props;
    const countryValue = (formValues.country && formValues.country.value) || 'US';
    
    function countryOnChange(args) {
        // reset the state value when the country changes
        if (args.country.value !== countryValue) {
            onChange({
                state: '',
                valid: false,
            })
        }
        onChange(args);
    }

    return (
        <>
            <FormSelect
                value={ countryValue }
                name="country"
                validate={ required }
                onChange={ countryOnChange }
                onFocus={ onFocus }
                label="Country"
                options={ countries }
                width="six"
            />
            <FormField 
                value={ formValues.name && formValues.name.value }
                name="name"
                validate={ required }
                onChange={ onChange }
                onFocus={ onFocus }
                placeholder="Company Name"
                label="Company Name"
            />
            <FormField 
                value={ formValues.address1 && formValues.address1.value }
                name="address1"
                validate={ required }
                onChange={ onChange }
                onFocus={ onFocus }
                placeholder="Address"
                label="Address"
            />
            <FormField 
                value={ formValues.address2 && formValues.address2.value }
                name="address2"
                onChange={ onChange }
                onFocus={ onFocus }
                placeholder="Address"
                label="Address 2"
            />
            <Form.Group>
                <FormField 
                    value={ formValues.city && formValues.city.value }
                    name="city"
                    validate={ required }
                    onChange={ onChange }
                    onFocus={ onFocus }
                    placeholder="City"
                    label="City"
                    width="seven"
                />
                <FormSelect
                    value={ formValues.state && formValues.state.value }
                    name="state"
                    validate={ required }
                    onChange={ onChange }
                    onFocus={ onFocus }
                    placeholder={ countryValue === 'US' ? 'State' : 'Province' }
                    label={ countryValue === 'US' ? 'State' : 'Province' }
                    options={ countryValue === 'US' ? states : canadaProvinces }
                    width="nine"
                />
            </Form.Group>
            <FormField 
                value={ formValues.zipcode && formValues.zipcode.value }
                name="zipcode"
                onChange={ onChange }
                onFocus={ onFocus }
                validate={ [required, minLength5] }
                maxLength={ 10 }
                placeholder="Zip Code"
                label="Zip Code"
                width="six"
            />
            <FormField 
                value={ formValues.company_phone && formValues.company_phone.value }
                name="company_phone"
                onChange={ onChange }
                onFocus={ onFocus }
                validate={ [required, phoneNumber] }
                normalize={ normalizePhone }
                placeholder="Phone Number"
                label="Phone Number"
                width="eight"
            />
        </>
    )
}

export default CompanyAddress
