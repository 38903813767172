import React from 'react'
import { Image } from 'semantic-ui-react'

import PrinterImage from '../../images/OM-Printer-and-Tablet-1.png'

const ThankYou = () =>  {
    return (
        <div style={{textAlign: "center"}}>
            <div style={ { marginBottom: '20px' } }>
                <h1>
                    We’re excited to get you up and running. 
                </h1>
                <h4 style={ { fontWeight: 'normal' } }>
                    Your equipment is being configured now, look for shipping details shortly.
                </h4>
            </div>

            <Image src={PrinterImage} size="large" style={ { width: '530px' } } />

            <div style={ { marginTop: '60px' } }>
                <a style={ { fontSize: '16px', paddingTop: '14px' } } target="_blank" rel="noopener noreferrer" className="ui button" href="https://www.ordermark.com/wireless-setup/">Next steps</a>
            </div>
        </div>
    )
}

export default ThankYou;