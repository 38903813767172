import React from 'react'
import { Link } from 'react-router-dom'

const Footer = (props) => {
    const year = new Date().getFullYear();
    return (
        <div className="footer">
            <div className="footer-left">
                &copy; { `${year} Ordermark` } &nbsp;
                <a href="https://www.ordermark.com/privacy/" target="_blank" rel="noopener noreferrer" className="underline">
                    Privacy
                </a>
                &nbsp; / &nbsp;
                <a href="https://www.ordermark.com/terms/" target="_blank" rel="noopener noreferrer" className="underline">
                    Terms
                </a>
            </div>

            <div>
                <Link to={`/help/${props.app_id}`}>
                    Help
                </Link>
            </div>
        </div>
    )
}

export default Footer