import React, { useReducer, useState, useEffect } from "react";
import { Form } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactGA from "react-ga";

import { isFormValid, getFormValues, setFormValues } from "../forms";
import { formatAddress } from "../ui/util";
import Button from "../ui/button";
import FormSection from "../forms/form-section";
import StoreAddress from "../forms/address-forms/store-address-form";
import Contact from "../forms/contact-forms";
import Creds from "../creds";
import Reach from "../reach";

const formReducer = (state, obj) => {
  return {
    ...state,
    ...obj,
  };
};

const onFieldFocused = (e) => {
  ReactGA.event({
    category: "Location",
    action: `${e.target.placeholder} focused`,
  });
};

const isLocationFormValid = (formValues, credValues) => {
  return isFormValid(formValues);
  // don't require OOS to register
  // && (
  //     (
  //         credValues.length > 0
  //     )
  // )
};

const formattedStoreAddress = (formValues) => {
  const v = getFormValues(formValues);
  return (
    <div>
      <div>{v.name}</div>
      <div>{v.address1}</div>
      {v.address2 && <div>{v.address2}</div>}
      {formatAddress({
        city: v.city,
        state: v.state,
        postalCode: v.zipcode,
        country: v.country,
      })}
    </div>
  );
};

const Location = (props) => {
  const {
    initialFormValues = {},
    creds = [],
    reach_requests = [],
    base_menu,
  } = props.currentLocation;
  const store_id = props.store_id;
  const opportunity_id = localStorage.getItem("opportunity_id");
  const app_id = localStorage.getItem("app_id");

  const [formValues, updateFormValues] = useReducer(
    formReducer,
    setFormValues(initialFormValues)
  );
  const [credValues, updateCredValues] = useState(creds);
  const [reachValues, updateReachValues] = useState(reach_requests);
  const [servicesType, setServicesType] = useState("creds");
  const [baseMenu, setBaseMenu] = useState(base_menu);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const partiallySaveStore = () => {
    // save the store and update URL to contain ID, for saving creds
    const values = getFormValues(formValues);
    values.creds = credValues;
    values.reach_requests = reachValues;
    props.dispatch.locationsModel.saveLocationAsync({
      replaceUrl: true,
      opportunity_id,
      store_id,
      values,
    });
  };

  const saveStore = () => {
    const values = getFormValues(formValues);
    values.creds = credValues;
    values.reach_requests = reachValues;
    values.base_menu = baseMenu;
    props.dispatch.locationsModel.saveLocationAsync({
      opportunity_id,
      store_id,
      values,
      credsFilled: true,
    });
    setFormSubmitted(true);
      props.dispatch.companyModel.getCompanyDataAsync(app_id)
  };

  const setInitialFormVals = (currentLocation) => {
    const { initialFormValues = {}, creds, reach_requests } = currentLocation;
    updateFormValues(setFormValues(initialFormValues));
    updateCredValues(creds);
    updateReachValues(reach_requests);
  };

  useEffect(() => {
    if (props.currentLocation && store_id) {
      setInitialFormVals(props.currentLocation);
      setBaseMenu(props.currentLocation.base_menu);
    }
  }, [props.currentLocation, store_id]);

  useEffect(() => {
    props.dispatch.partnersModel.getPartnersAsync();
    if (store_id) {
      props.dispatch.locationsModel.getCurrentLocation(store_id);
    }
  }, [props.dispatch.locationsModel, props.dispatch.partnersModel, store_id]);

  const showServicesSection = store_id != null;

  const specifiedProviders = credValues
    ? credValues.map((cred) => {
        return cred.provider;
      })
    : [];

  const baseMenuRequired = false;
  // specifiedProviders.includes('ubereats') ||
  // specifiedProviders.includes('postmates');

  return (
    <div>
      <Form>
        <FormSection title="Store Info">
          <StoreAddress
            formValues={formValues}
            onChange={updateFormValues}
            onFocus={onFieldFocused}
            type="Store"
          />
        </FormSection>
        <FormSection title="Store Contact">
          <Contact
            formValues={formValues}
            onChange={updateFormValues}
            onFocus={onFieldFocused}
          />
        </FormSection>
        {!showServicesSection ? (
          <>
            <Button
              disabled={!isFormValid(formValues)}
              onClick={partiallySaveStore}
            >
              Next
            </Button>
            {formValues.cuisine_type &&
            formValues.cuisine_type.valid === false ? (
              <div className="error-text" style={{ marginTop: "10px" }}>
                *Cuisine Type is required.
              </div>
            ) : null}
          </>
        ) : null}
        {showServicesSection && (
          <>
            <div className="divider"></div>
            <FormSection title="Store Services">
              <div style={{ display: "none" }}>
                <div style={{ flex: 3 }}>
                  {formattedStoreAddress(formValues)}
                </div>
                <div style={{ flex: 5 }}>
                  <div style={{ marginBottom: "10px" }}>
                    <strong>
                      Does this location currently use online ordering services?
                    </strong>
                  </div>
                  <div style={{ width: "50%" }}>
                    <Form.Group widths="equal">
                      <Form.Radio
                        checked={servicesType === "creds"}
                        label="Yes"
                        value="creds"
                        onChange={(e, { value }) => setServicesType(value)}
                      />
                      <Form.Radio
                        checked={servicesType === "reach"}
                        label="No"
                        value="reach"
                        onChange={(e, { value }) => setServicesType(value)}
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
              {servicesType === "creds" ? (
                <Creds
                  providers={props.providers}
                  address={{
                    address1: formValues.address1 && formValues.address1.value,
                    city: formValues.city && formValues.city.value,
                    state: formValues.state && formValues.state.value,
                    zipcode: formValues.zipcode && formValues.zipcode.value,
                  }}
                  storeId={store_id}
                  onChange={updateCredValues}
                  onFocus={onFieldFocused}
                  creds={credValues}
                />
              ) : (
                <Reach
                  onChange={updateReachValues}
                  updateFormValues={updateFormValues}
                  reachValues={reachValues}
                  onFocus={onFieldFocused}
                  nss_notes={formValues.nss_notes && formValues.nss_notes.value}
                />
              )}
            </FormSection>
            {baseMenuRequired && (
              <FormSection title="Base Menu">
                <p>Where do you want your menu to come from? (Required)</p>
                <div className="card-box">
                  {specifiedProviders.includes("ubereats") && (
                    <Form.Radio
                      checked={baseMenu === "ubereats"}
                      label="Uber Eats"
                      value="ubereats"
                      onChange={(e, { value }) => setBaseMenu(value)}
                    />
                  )}
                  {specifiedProviders.includes("postmates") && (
                    <Form.Radio
                      checked={baseMenu === "postmates"}
                      label="Postmates"
                      value="postmates"
                      onChange={(e, { value }) => setBaseMenu(value)}
                    />
                  )}
                  <Form.Radio
                    checked={baseMenu === "none"}
                    label="None"
                    value="none"
                    onChange={(e, { value }) => setBaseMenu(value)}
                  />
                </div>
              </FormSection>
            )}
            <div className="divider card-divider" />
            <Button
              className="button-completion"
              disabled={
                (baseMenuRequired && !baseMenu) ||
                formSubmitted ||
                !isLocationFormValid(formValues, credValues)
              }
              onClick={saveStore}
              loading={formSubmitted}
            >
              {store_id ? "Save Store" : "Add Store"}
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { opportunity_id, store_id } = ownProps.match.params;
  const { currentLocation } = state.locationsModel;
  const reachModel = state.reachModel;
  const providers = state.partnersModel.providers;

  return {
    opportunity_id,
    store_id,
    currentLocation,
    reachModel,
    providers,
  };
};
export default connect(mapStateToProps)(Location);
