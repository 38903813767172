import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import PlaidVerify from './Plaid-Verify';

const MicroDepositVerify = (props) => {
    useEffect(()=> {
        const initialLoad = async () => {
            props.dispatch.billingModel.getPlaidDataAsync(props.app_id);
        }
        initialLoad();
    }, [props.app_id, props.dispatch.billingModel])
    return (
        <div style={ {'textAlign': 'center' } }>
            Click below to verify the amounts sent to you via Plaid
            <PlaidVerify
                ACHToken={ props.ACHToken }
                verifyPlaid={ props.dispatch.billingModel.verifyPlaidAsync }
                app_id={ props.app_id }
            />
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        ACHToken: state.billingModel.ACHToken,
        app_id: ownProps.match.params.app_id
    }
}

export default connect(mapStateToProps)(MicroDepositVerify);