import { createModel } from '@rematch/core';

import request from '../utils/request'
import history from '../../history'

export const companyModel = createModel({
    state: {},

    reducers: {
        loadCompanyData: (state, payload) => {
            return { ...state, ...payload };
        },
        loadTermsData: (state, payload) => {
            return { ...state,  account_terms_policy: payload };
        },
        loadCompletionStatus: (state, payload) => {
            return { ...state, is_complete: payload.is_complete };
        }
    },
    effects: (dispatch) => ({
        async getCompanyDataAsync() {
          const app_id = localStorage.getItem("app_id");
          request
            .get(`/companies/${app_id}/`)
            .then((resp) => {
              const {
                contact,
                address,
                sales_representative,
                plan,
                account_terms_policy,
                is_complete,
                record_type,
                opportunities,
                submitted_to_salesforce
              } = resp.data;

                const payload = {
                  sales_representative,
                  ...address,
                  company_phone: address.phone,
                  ...contact,
                  account_terms_policy,
                  plan,
                  is_complete,
                  record_type,
                  opportunities,
                  submitted_to_salesforce
                }
                this.loadCompanyData(payload);
            })
            .catch((e) => {
              console.log(e);
            })
      },
        async postCompanyContactDataAsync(payload) {
          const { referee_code }  = payload;
          const {
            first_name,
            last_name,
            email,
            phone,
            name,
            address1,
            address2,
            city,
            state,
            zipcode,
            company_phone,
            country,
          } = payload.values;
          let opportunity_id = localStorage.getItem("opportunity_id");

          const app_id = payload.app_id;
          const dataToSend = {
            contact: {
              first_name,
              last_name,
              email,
              phone,
            },
            address: {
              name,
              address1,
              address2,
              city,
              state,
              zipcode,
              phone: company_phone,
              country,
            },
            referee_code,
          };
          const putURL =
            opportunity_id == null
              ? `/companies/${app_id}/`
              : `/companies/${app_id}/?opportunity_id=${opportunity_id}`;
          request
            .put(putURL, dataToSend)
            .then((resp) => {
              const {
                contact,
                address,
                sales_representative,
                plan,
                account_terms_policy,
                is_complete,
                opportunities,
              } = resp.data;
              const payload = {
                sales_representative,
                ...address,
                company_phone: address.phone,
                ...contact,
                account_terms_policy,
                plan,
                is_complete,
                opportunities,
              };

              this.loadCompanyData(payload);
              history.push(`/payments/${app_id}`);
            })
            .catch((e) => {
              console.log(e);
            });
        },
        async postTermsData(payload) {
          const { account_terms_policy, app_id } = payload;
          const dataToSend = {
            account_terms_policy,
          };
          let opportunity_id = localStorage.getItem("opportunity_id");
          const putURL =
            opportunity_id == null
              ? `companies/${app_id}/`
              : `companies/${app_id}/?opportunity_id=${opportunity_id}`;
          request.put(putURL, dataToSend).then((resp) => {
            this.loadTermsData(resp.data.account_terms_policy);
          });
        },
        async completeFlowAsync(payload) {
          const { app_id, opportunity_id } = payload;
          const dataToSend = {
            is_complete: true,
          };
          const putURL =
            opportunity_id == null
              ? `/companies/${app_id}/`
              : `/companies/${app_id}/?opportunity_id=${opportunity_id}`;
          request
            .put(putURL, dataToSend)
            .then(async (resp) => {
              history.push(`/thankyou/${app_id}`);
              this.loadCompletionStatus(resp.data);
            })
            .catch((e) => {
              console.log(e);
            })
        }
      })
  })
