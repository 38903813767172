import React from 'react';
import { Segment, Header } from 'semantic-ui-react';

import ProviderIcon from './providerIcon';
import DropDownCard from '../dropdown-card';

const ProviderSegment = ({ provider, showCheckbox, onChange, checked }) => {
    return (
        <Segment style={{backgroundColor: "#f4f4f4"}}>
            <DropDownCard
                icon={ <ProviderIcon provider={ provider } /> }
                label={
                    <Header className="card-title">
                        <Header.Content>
                            { provider }
                            <Header.Subheader>
                                Online Ordering Provider
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                }
                provider={ provider } 
                showCheckbox={ showCheckbox }
                onChange={ onChange }
                checked={ checked }
            />
        </Segment>
    );
};

export default ProviderSegment;