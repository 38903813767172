export const countries = [{value: "US", text: "US"}, {value: "CA", text: "Canada"}];
export const canadaProvinces = [{value:"Alberta",text:"Alberta"},{value:"British Columbia",text:"British Columbia"},{value:"Manitoba",text:"Manitoba"},{value:"New Brunswick",text:"New Brunswick"},{value:"Newfoundland and Labrador",text:"Newfoundland and Labrador"},{value:"Northwest Territories",text:"Northwest Territories"},{value:"Nova Scotia",text:"Nova Scotia"},{value:"Nunavut",text:"Nunavut"},{value:"Ontario",text:"Ontario"},{value:"Prince Edward Island",text:"Prince Edward Island"},{value:"Quebec",text:"Quebec"},{value:"Saskatchewan",text:"Saskatchewan"},{value:"Yukon Territory",text:"Yukon Territory"}];
export const states = [{value: "AL", text: "AL-Alabama"}, {value: "AK", text: "AK-Alaska"}, {value: "AZ", text: "AZ-Arizona"}, {value: "AR", text: "AR-Arkansas"}, {value: "CA", text: "CA-California"}, {value: "CO", text: "CO-Colorado"}, {value: "CT", text: "CT-Connecticut"}, {value: "DC", text: "DC-District of Columbia"}, {value: "DE", text: "DE-Delaware"}, {value: "FL", text: "FL-Florida"}, {value: "GA", text: "GA-Georgia"}, {value: "HI", text: "HI-Hawaii"}, {value: "ID", text: "ID-Idaho"}, {value: "IL", text: "IL-Illinois"}, {value: "IN", text: "IN-Indiana"}, {value: "IA", text: "IA-Iowa"}, {value: "KS", text: "KS-Kansas"}, {value: "KY", text: "KY-Kentucky"}, {value: "LA", text: "LA-Louisiana"}, {value: "ME", text: "ME-Maine"}, {value: "MD", text: "MD-Maryland"}, {value: "MA", text: "MA-Massachusetts"}, {value: "MI", text: "MI-Michigan"}, {value: "MN", text: "MN-Minnesota"}, {value: "MS", text: "MS-Mississippi"}, {value: "MO", text: "MO-Missouri"}, {value: "MT", text: "MT-Montana"}, {value: "NE", text: "NE-Nebraska"}, {value: "NV", text: "NV-Nevada"}, {value: "NH", text: "NH-New Hampshire"}, {value: "NJ", text: "NJ-New Jersey"}, {value: "NM", text: "NM-New Mexico"}, {value: "NY", text: "NY-New York"}, {value: "NC", text: "NC-North Carolina"}, {value: "ND", text: "ND-North Dakota"}, {value: "OH", text: "OH-Ohio"}, {value: "OK", text: "OK-Oklahoma"}, {value: "OR", text: "OR-Oregon"}, {value: "PA", text: "PA-Pennsylvania"}, {value: "RI", text: "RI-Rhode Island"}, {value: "SC", text: "SC-South Carolina"}, {value: "SD", text: "SD-South Dakota"}, {value: "TN", text: "TN-Tennessee"}, {value: "TX", text: "TX-Texas"}, {value: "UT", text: "UT-Utah"}, {value: "VT", text: "VT-Vermont"}, {value: "VA", text: "VA-Virginia"}, {value: "WA", text: "WA-Washington"}, {value: "WV", text: "WV-West Virginia"}, {value: "WI", text: "WI-Wisconsin"}, {value: "WY", text: "WY-Wyoming"}]
export const cuisine_types = [
    {value: "Afghan", text: "Afghan"}, 
    {value: "African", text: "African"}, 
    {value: "American", text: "American"}, 
    {value: "Arabian", text: "Arabian"}, 
    {value: "Argentinean", text: "Argentinean"}, 
    {value: "Armenian", text: "Armenian"}, 
    {value: "Asian", text: "Asian"}, 
    {value: "Asian Fusion", text: "Asian Fusion"}, 
    {value: "Australian", text: "Australian"}, 
    {value: "Austrian", text: "Austrian"}, 
    {value: "Bakery", text: "Bakery"}, 
    {value: "Bangladeshi", text: "Bangladeshi"}, 
    {value: "Bar Food", text: "Bar Food"}, 
    {value: "Basque", text: "Basque"}, 
    {value: "BBQ", text: "BBQ"}, 
    {value: "Belgian", text: "Belgian"}, 
    {value: "Boba", text: "Boba"}, 
    {value: "Brasseries", text: "Brasseries"}, 
    {value: "Brazilian", text: "Brazilian"}, 
    {value: "Breakfast and Brunch", text: "Breakfast and Brunch"}, 
    {value: "British", text: "British"}, 
    {value: "Buffets", text: "Buffets"}, 
    {value: "Bulgarian", text: "Bulgarian"}, 
    {value: "Burgers", text: "Burgers"}, 
    {value: "Burmese", text: "Burmese"}, 
    {value: "Cafes", text: "Cafes"}, 
    {value: "Cafeteria", text: "Cafeteria"}, 
    {value: "Cajun", text: "Cajun"}, 
    {value: "Calabrian", text: "Calabrian"}, 
    {value: "Cambodian", text: "Cambodian"}, 
    {value: "Cantonese", text: "Cantonese"}, 
    {value: "Caribbean", text: "Caribbean"}, 
    {value: "Catalan", text: "Catalan"}, 
    {value: "Cheesesteaks", text: "Cheesesteaks"}, 
    {value: "Chicken Shop", text: "Chicken Shop"}, 
    {value: "Chicken Wings", text: "Chicken Wings"}, 
    {value: "Chinese", text: "Chinese"}, 
    {value: "Coffee and Tea", text: "Coffee and Tea"}, 
    {value: "Colombian", text: "Colombian"}, 
    {value: "Comfort Food", text: "Comfort Food"}, 
    {value: "Creperies", text: "Creperies"}, 
    {value: "Cuban", text: "Cuban"}, 
    {value: "Czech", text: "Czech"}, 
    {value: "Deli", text: "Deli"}, 
    {value: "Desserts", text: "Desserts"}, 
    {value: "Dim Sum", text: "Dim Sum"}, 
    {value: "Diners", text: "Diners"}, 
    {value: "Dinner Theater", text: "Dinner Theater"}, 
    {value: "Dominican", text: "Dominican"}, 
    {value: "Eastern European", text: "Eastern European"}, 
    {value: "Egyptian", text: "Egyptian"}, 
    {value: "Ethiopian", text: "Ethiopian"}, 
    {value: "Falafel", text: "Falafel"}, 
    {value: "Fast Food", text: "Fast Food"}, 
    {value: "Filipino", text: "Filipino"}, 
    {value: "Fish & Chips", text: "Fish & Chips"}, 
    {value: "Fondue", text: "Fondue"}, 
    {value: "Food Court", text: "Food Court"}, 
    {value: "Food Stands", text: "Food Stands"}, 
    {value: "Food Truck", text: "Food Truck"}, 
    {value: "French", text: "French"}, 
    {value: "Game Meat", text: "Game Meat"}, 
    {value: "Gastropubs", text: "Gastropubs"}, 
    {value: "Georgian", text: "Georgian"}, 
    {value: "German", text: "German"}, 
    {value: "Gluten Free", text: "Gluten Free"}, 
    {value: "Greek", text: "Greek"}, 
    {value: "Guamanian", text: "Guamanian"}, 
    {value: "Hainan", text: "Hainan"}, 
    {value: "Haitian", text: "Haitian"}, 
    {value: "Halal", text: "Halal"}, 
    {value: "Hawaiian", text: "Hawaiian"}, 
    {value: "Healthy", text: "Healthy"}, 
    {value: "Himalayan/Nepalese", text: "Himalayan/Nepalese"}, 
    {value: "Honduran", text: "Honduran"}, 
    {value: "Hong Kong Style Cafe", text: "Hong Kong Style Cafe"},
    {value: "Hot Dogs", text: "Hot Dogs"}, 
    {value: "Hot Pot", text: "Hot Pot"}, 
    {value: "Hungarian", text: "Hungarian"}, 
    {value: "Iberian", text: "Iberian"}, 
    {value: "Ice Cream and Frozen Yogurt", text: "Ice Cream and Frozen Yogurt"}, 
    {value: "Indian", text: "Indian"}, 
    {value: "Indonesian", text: "Indonesian"}, 
    {value: "Irish", text: "Irish"}, 
    {value: "Italian", text: "Italian"}, 
    {value: "Izakaya", text: "Izakaya"}, 
    {value: "Japanese", text: "Japanese"}, 
    {value: "Japanese Curry", text: "Japanese Curry"}, 
    {value: "Juice and Smoothies", text: "Juice and Smoothies"}, 
    {value: "Kebab", text: "Kebab"}, 
    {value: "Korean", text: "Korean"}, 
    {value: "Kosher", text: "Kosher"}, 
    {value: "Laotian", text: "Laotian"}, 
    {value: "Latin American", text: "Latin American"}, 
    {value: "Latin Fusion", text: "Latin Fusion"}, 
    {value: "Lebanese", text: "Lebanese"}, 
    {value: "Live/Raw Food", text: "Live/Raw Food"}, 
    {value: "Malaysian", text: "Malaysian"}, 
    {value: "Mauritius", text: "Mauritius"}, 
    {value: "Mediterranean", text: "Mediterranean"}, 
    {value: "Mexican", text: "Mexican"}, 
    {value: "Middle Eastern", text: "Middle Eastern"}, 
    {value: "Modern European", text: "Modern European"}, 
    {value: "Mongolian", text: "Mongolian"}, 
    {value: "Moroccan", text: "Moroccan"}, 
    {value: "New Mexican Cuisine", text: "New Mexican Cuisine"}, 
    {value: "Nicaraguan", text: "Nicaraguan"}, 
    {value: "Noodles", text: "Noodles"}, 
    {value: "Pakistani", text: "Pakistani"}, 
    {value: "Pan Asian", text: "Pan Asian"}, 
    {value: "Persian/Iranian", text: "Persian/Iranian"}, 
    {value: "Peruvian", text: "Peruvian"}, 
    {value: "Pizza", text: "Pizza"}, 
    {value: "Polish", text: "Polish"}, 
    {value: "Polynesian", text: "Polynesian"}, 
    {value: "Pop-Up Restaurants", text: "Pop-Up Restaurants"}, 
    {value: "Portuguese", text: "Portuguese"}, 
    {value: "Poutineries", text: "Poutineries"},
    {value: "Pretzels", text: "Pretzels"}, 
    {value: "Pub", text: "Pub"}, 
    {value: "Puerto Rican", text: "Puerto Rican"}, 
    {value: "Ramen", text: "Ramen"}, 
    {value: "Reunion", text: "Reunion"}, 
    {value: "Russian", text: "Russian"}, 
    {value: "Salads", text: "Salads"}, 
    {value: "Salvadoran", text: "Salvadoran"}, 
    {value: "Sandwiches", text: "Sandwiches"}, 
    {value: "Sardinian", text: "Sardinian"}, 
    {value: "Scandinavian", text: "Scandinavian"}, 
    {value: "Scottish", text: "Scottish"}, 
    {value: "Seafood", text: "Seafood"}, 
    {value: "Senegalese", text: "Senegalese"}, 
    {value: "Shanghainese", text: "Shanghainese"}, 
    {value: "Sicilian", text: "Sicilian"}, 
    {value: "Singaporean", text: "Singaporean"}, 
    {value: "Slovakian", text: "Slovakian"},
    {value: "Snack", text: "Snack"},
    {value: "Soul Food", text: "Soul Food"}, 
    {value: "Soup", text: "Soup"}, 
    {value: "South African", text: "South African"}, 
    {value: "Southern", text: "Southern"}, 
    {value: "Spanish", text: "Spanish"}, 
    {value: "Sri Lankan", text: "Sri Lankan"}, 
    {value: "Steakhouses", text: "Steakhouses"}, 
    {value: "Supper Clubs", text: "Supper Clubs"}, 
    {value: "Sushi", text: "Sushi"}, 
    {value: "Sushi Bars", text: "Sushi Bars"}, 
    {value: "Syrian", text: "Syrian"}, 
    {value: "Szechuan", text: "Szechuan"}, 
    {value: "Tacos", text: "Tacos"}, 
    {value: "Taiwanese", text: "Taiwanese"}, 
    {value: "Tapas/Small Plates", text: "Tapas/Small Plates"}, 
    {value: "Tapas Bars", text: "Tapas Bars"}, 
    {value: "Teppanyaki", text: "Teppanyaki"}, 
    {value: "Tex-Mex", text: "Tex-Mex"}, 
    {value: "Thai", text: "Thai"}, 
    {value: "Themed Cafes", text: "Themed Cafes"}, 
    {value: "Trinidadian", text: "Trinidadian"}, 
    {value: "Turkish", text: "Turkish"}, 
    {value: "Tuscan", text: "Tuscan"}, 
    {value: "Ukrainian", text: "Ukrainian"}, 
    {value: "Uzbek", text: "Uzbek"}, 
    {value: "Vegan", text: "Vegan"}, 
    {value: "Vegetarian", text: "Vegetarian"}, 
    {value: "Venezuelan", text: "Venezuelan"}, 
    {value: "Vietnamese", text: "Vietnamese"}, 
    {value: "Waffles", text: "Waffles"}, 
    {value: "Wine & Cheese", text: "Wine & Cheese"}, 
    {value: "Wings", text: "Wings"}, 
    {value: "Wraps", text: "Wraps"}, 
    {value: "Other", text: "Other"}, 
]