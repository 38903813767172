import React from 'react';

const FormSection = (props) => {
    return (
        <div style={ { marginBottom: '45px' } }>
            <h3 style={ { marginBottom: '25px' } }>
                { props.title }
            </h3>
            { props.children }
        </div>
    );
};

export default FormSection;