import React from 'react'
import FormField from '../form-field';
import { Form } from 'semantic-ui-react';

import normalizePhone from '../../forms/normalizePhone';
import { required, phoneNumber, isEmail } from '../../forms/validate';


const Contact = (props) =>  {
    const { formValues = {}, onFocus, onChange } = props;

    return (
        <>
            <Form.Group widths="equal">
                <FormField 
                    value={ formValues.first_name && formValues.first_name.value }
                    name="first_name"
                    onChange={ onChange }
                    onFocus={ onFocus }
                    validate={ required }
                    placeholder="First Name"
                    label="First Name"
                />
                <FormField 
                    value={ formValues.last_name && formValues.last_name.value }
                    name="last_name"
                    onChange={ onChange }
                    onFocus={ onFocus }
                    validate={ required }
                    placeholder="Last Name"
                    label="Last Name"
                />
            </Form.Group>
            <FormField 
                value={ formValues.email && formValues.email.value }
                name="email"
                onChange={ onChange }
                onFocus={ onFocus }
                validate={ [required, isEmail] }
                placeholder="Email Address"
                label="Email Address"
            />
            <FormField 
                value={ formValues.phone && formValues.phone.value }
                name="phone"
                onChange={ onChange }
                onFocus={ onFocus }
                validate={ [required, phoneNumber] }
                normalize={ normalizePhone }
                placeholder="Phone Number"
                label="Phone Number"
                width="eight"
            />
        </>
    )
}

export default Contact
