import React from 'react'
import PlaidLink from 'react-plaid-link'
import ReactGA from 'react-ga';
import { PLAID_PUBLIC_KEY } from '../../config';

const gaVerifyPlaid = (app_id) => {
    ReactGA.event({
        category: 'Plaid Verification',
        action: `micro-deposit verified for ${ app_id }`
    });
};

const PlaidVerify = (props) => {
    const handleOnExit = (error, metadata)=>{
        if (error !== null) {
        }
    };
    const handleOnSuccess = (token, metadata) => {
        gaVerifyPlaid(props.app_id);
        props.verifyPlaid(props.app_id);
    };

    let env = process.env.NODE_ENV === 'development' || window.location.hostname.includes('staging-2') ? 'sandbox' : 'production'

    if (!props.ACHToken) {
        return null
    }

    return (
        <PlaidLink
            clientName="Ordermark"
            env={ `${env}` }
            product={ ["auth"] }
            user={{
                legalName: 'Daniel Ham',
                emailAddress: 'daniel.ham@getordermark.com',
            }}
            token={ props.ACHToken }
            apiVersion={'v2'}
            publicKey={ PLAID_PUBLIC_KEY }
            onSuccess={ handleOnSuccess }
            onExit={ handleOnExit }
            className="ui button ACH-verify-button"
            style={{}}
        >
            Verify
        </PlaidLink>
    )
}

export default PlaidVerify
