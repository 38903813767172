import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { Image } from 'semantic-ui-react'
import PrinterImage from '../../images/OM-Printer-and-Tablet-1.png'
import { Elements, injectStripe } from 'react-stripe-elements';

import BillingInfo from './BillingInfo';
import history from '../../history';
import CredCreditCardPaymentOption from './CreditCardPaymentOption';
import ACHPaymentOption from './ACHPaymentOption';
import TermsOfService from '../terms-of-service';
import TabSwitcher from '../ui/tab-switcher';
import Button from '../ui/button';

const envIsDev = process.env.REACT_APP_ENV === 'development';

const gaPaymentEvent = (paymentType, app_id) => {
    ReactGA.event({
        category: 'Payments',
        action: `${ paymentType } token created for ${ app_id } `
    });
};
const Billing = (props) => {
    const [termsChecked, termsChangeHandler] = useState(props.account_terms_policy || false);
    const [activeTab, switchActiveTab] = useState(props.token_type === 'ACH' ? 1 : 0);
    const [stripeValid, setStripeValidity] = useState(props.ccToken || false);
    const app_id = props.app_id;

    const handleStripeSubmit = async () => {
        let token;
        
        try {
            token = await props.stripe.createToken();
        } catch(err) {

        }

        return token;
    }
    const handleButtonStatus = () => {
        return envIsDev ? true : termsChecked && (stripeValid || props.ACHToken || props.ccToken);
    }
    const handleButttonClick = async () => {
        let valid = true;
        const termsPayload = {
            app_id,
            account_terms_policy: termsChecked
        };

        if (activeTab === 0 && !props.ccToken) {
            const token = await handleStripeSubmit();

            if (token.token || envIsDev) {
                const billingPayload = {
                    token: token.token,
                    app_id,
                    token_type: 'Credit Card',
                };
                gaPaymentEvent('Stripe', app_id);
                props.dispatch.billingModel.postPaymentDataAsync(billingPayload);
            } else {
                valid = false;
            }
        } 
        
        if (valid || envIsDev) {
            props.dispatch.companyModel.postTermsData(termsPayload);
            history.push(`/locations/${props.app_id}`);
        }
    }

    useEffect(() => {
        props.dispatch.billingModel.getBillingDataAsync(props.app_id);
        props.dispatch.companyModel.getCompanyDataAsync(props.app_id);
    }, [props.app_id, props.dispatch.billingModel, props.dispatch.companyModel]);
    useEffect(() => {
        termsChangeHandler(props.account_terms_policy);
    }, [props.account_terms_policy]);
    useEffect(() => {
        setStripeValidity(props.ccToken);
    }, [props.ccToken]);
    useEffect(() => {
        props.token_type === 'ACH' ? switchActiveTab(1) : switchActiveTab(0);
    }, [props.token_type]);

    return (
        <div>
            <div>
                <div style={ { textAlign: 'center' } }>
                    <h2>The easiest way to manage all your online orders.</h2>
                    <h4 style={ { marginBottom: '30px' } }>Trusted by thousands of restaurant brands nationwide.</h4>
                    <Image src={ PrinterImage } size="large" style={ { width: '530px' } } />
                    <BillingInfo 
                        fixed_monthly_rate={ props.fixed_monthly_rate }
                        fee_transaction={ props.fee_transaction }
                        setup_fee={ props.setup_fee }
                    />
                </div>
            </div>
                <div className="divider"></div>
                <TermsOfService
                    name="terms_of_service"
                    checked={ termsChecked }
                    changeHandler={ termsChangeHandler }
                />
            <div className="divider"></div>
            <div style={ { marginBottom: '40px' } }>
                <h2 style={ { marginBottom: '20px' } }>Payment Information</h2>
                <TabSwitcher
                    tabs={
                        [
                            { "label": "Credit Card", "icon": "credit card" },
                            { "label": "ACH", "icon": "ach" },
                        ]
                    }
                    switchActiveTab={ switchActiveTab }
                    activeTab={ activeTab }
                >
                    <CredCreditCardPaymentOption
                        setStripeValidity = { setStripeValidity }
                    />
                    <ACHPaymentOption
                        name={ `${props.first_name} ${props.last_name}` }
                        email={ props.email }
                        ACHToken={ props.ACHToken }
                        postPaymentDataAsync={ props.dispatch.billingModel.postPaymentDataAsync }
                        app_id={ props.app_id }
                        gaPaymentEvent = { gaPaymentEvent }
                    />
                </TabSwitcher>
            </div>
            <Button disabled={ !handleButtonStatus() } onClick={ handleButttonClick }>Next</Button>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    let { fixed_monthly_rate, fee_transaction, setup_fee } = state.companyModel.plan || {};
    let { first_name, last_name, email, opportunities } = state.companyModel || {};
    let { account_terms_policy } = state.companyModel || {};
    let { ACHToken, ACHMetadata, ccToken, token_type } = state.billingModel || {};

    return {
        fixed_monthly_rate,
        fee_transaction,
        setup_fee,
        ACHToken,
        ACHMetadata,
        ccToken,
        account_terms_policy,
        first_name,
        last_name,
        email,
        token_type,
        app_id: ownProps.match.params.app_id,
        opportunities
    }
}

const WrappedBilling = injectStripe(connect(mapStateToProps)(Billing));

const elementsWrapper = (props) => {
    return (
        <Elements>
            <WrappedBilling match={props.match}/>
        </Elements>
    )
}

export default elementsWrapper