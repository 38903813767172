export const formatAddress = (attributes) => {
    const {
        line1,
        line2,
        city,
        state,
        region,
        postalCode,
        country,
    } = attributes;
    const fields = [
        (line1 || '') + (line2 ? ` ${line2}` : ''),
        (city || ''),
        (!country || country === 'US') ? (state || '') : (region || ''),
    ].filter(el => el);
    let address = fields.join(', ');
    if (postalCode) {
        address += ` ${postalCode} `;
    }
    if (country) {
        address += ` ${country} `;
    }
    return address;
};
