import React from 'react'
import { ReactComponent as OrdermarkLogo } from '../../images/ordermark-logo.svg';

const Header = (props) =>  {
    return (
        <div>
            <OrdermarkLogo style={ { width: '175px', margin: '30px auto', display: 'block' } } />
        </div>
    )
}

export default Header;

