import React, { useState, useEffect } from 'react';
import { ReactComponent as ACHSvg } from '../../images/ACH_illustration-1.svg';
import { ReactComponent as CreditCardSvg } from '../../images/credit-card-icon.svg';

const TabSwitcher = (props) => {
    const [activeTab, changeActiveTab] = useState(props.activeTab || 0);
    const changeTab = (idx) => {
        props.switchActiveTab && props.switchActiveTab(idx);
        changeActiveTab(idx);
    };
    const svgMap = {
        'credit card': <CreditCardSvg  style={ { width: '20px' } }/>,
        'ach': <ACHSvg  style={ { width: '20px' } }/>
    }
    useEffect(() => {
        if (props.activeTab) {
            changeActiveTab(props.activeTab)
        }
    }, [props.activeTab]);

    return (
        <div>
            <div className="tab-switcher flex-around radio-group-bottom-space">
                {
                    props.tabs && props.tabs.map((tab, idx) => {
                        return (
                            <div
                                key={idx}
                                className={ 
                                    activeTab === idx ?
                                    "tab active" 
                                    : "tab inactive"
                                }
                                onClick={() => changeTab(idx)}
                                style={{display: "flex", alignItems:"center", width: '50%'}}
                            >
                                <div style={ { margin: '0 auto', display: 'flex' } }>
                                    {
                                        tab.icon 
                                        && <div>
                                            { svgMap[tab.icon] }
                                        </div>
                                    }
                                    <div style={ { marginTop: "0px", marginLeft: "5px" } }>
                                        { tab.label }
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div>
                { props.children && props.children[activeTab] }
            </div>
        </div>
    );
};

export default TabSwitcher;