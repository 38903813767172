import React, { useState } from 'react'
import FormField from '../form-field';
import FormSelect from '../form-select';
import { Form } from 'semantic-ui-react';
import { countries, states, canadaProvinces, cuisine_types } from '../../../dummyData/constants';
import { Popup } from 'semantic-ui-react';
import { InfoCircleFilled } from '@ant-design/icons';

import { required, minLength5, phoneNumber } from '../../forms/validate';
import normalizePhone from '../../forms/normalizePhone';

const StoreAddress = (props) =>  {
    const { formValues = {}, onFocus, onChange } = props;
    const countryValue = (formValues.country && formValues.country.value) || 'US';
    const [formState, setFormState] = useState({value: false, valid: false})

    //initalize is_virtual_kitchen value
    formValues.is_virtual_kitchen && formValues.is_virtual_kitchen.value != null ? 
    formValues.is_virtual_kitchen.valid = true : formValues.is_virtual_kitchen = formState

    function countryOnChange(args) {
        // reset the state value when the country changes
        if (args.country.value !== countryValue) {
            onChange({
                state: '',
                valid: false,
            })
        }
        onChange(args);
    }

    const setStoreType = (formState) => {
        setFormState({
            ...formState,
        });
        formValues.is_virtual_kitchen = formState
        onChange(formState)
    };

    return (
        <div>
            <FormSelect
                value={ countryValue }
                name="country"
                validate={ required }
                onChange={ countryOnChange }
                onFocus={ onFocus }
                label="Country"
                options={ countries }
                width="six"
            />
            <FormField 
                value={ formValues.store_name && formValues.store_name.value }
                name="store_name"
                validate={ required }
                onChange={ onChange }
                onFocus={ onFocus }
                placeholder="Store Name"
                label="Store Name"
            />
            <FormSelect
                value={ formValues.cuisine_type && formValues.cuisine_type.value }
                name="cuisine_type"
                validate={ required }
                onChange={ onChange }
                onFocus={ onFocus }
                placeholder="Select Cuisine"
                label="Cuisine Type (Required)"
                options={ cuisine_types }
                width="six"
                style={ { minWidth: '240px' } }
            />
            <FormField 
                value={ formValues.address1 && formValues.address1.value }
                name="address1"
                validate={ required }
                onChange={ onChange }
                onFocus={ onFocus }
                placeholder="Address"
                label="Address"
            />
            <FormField 
                value={ formValues.address2 && formValues.address2.value }
                name="address2"
                onChange={ onChange }
                onFocus={ onFocus }
                placeholder="Address"
                label="Address 2"
            />
            <Form.Group>
                <FormField 
                    value={ formValues.city && formValues.city.value }
                    name="city"
                    validate={ required }
                    onChange={ onChange }
                    onFocus={ onFocus }
                    placeholder="City"
                    label="City"
                    width="seven"
                />
                <FormSelect
                    value={ formValues.state && formValues.state.value }
                    name="state"
                    validate={ required }
                    onChange={ onChange }
                    onFocus={ onFocus }
                    placeholder={ countryValue === 'US' ? 'State' : 'Province' }
                    label={ countryValue === 'US' ? 'State' : 'Province' }
                    options={ countryValue === 'US' ? states : canadaProvinces }
                    width="nine"
                />
            </Form.Group>
            <FormField 
                value={ formValues.zipcode && formValues.zipcode.value }
                name="zipcode"
                onChange={ onChange }
                onFocus={ onFocus }
                validate={ [required, minLength5] }
                maxLength={ 10 }
                placeholder="Zip Code"
                label="Zip Code"
                width="six"
            />
            <FormField 
                value={ formValues.store_phone && formValues.store_phone.value }
                name="store_phone"
                onChange={ onChange }
                onFocus={ onFocus }
                validate={ [required, phoneNumber] }
                normalize={ normalizePhone }
                placeholder="Phone Number"
                label="Phone Number"
                width="eight"
            />
            <Form.Field> 
            <label> Store Type </label>
                <Form.Group>
                    <Form.Radio
                        name="brick_mortar"
                        checked={ !formValues.is_virtual_kitchen.value }
                        onChange={() => setStoreType({ 
                            ...formState, 
                            value: false,
                            valid: true
                        })}
                        label={"Physical Location"}
                    />
                    <Popup
                        trigger={
                            <InfoCircleFilled style={{color: "grey"}} className="text-black align-middle ml-2" />
                        }
                    >
                        restaurant located at this address
                    </Popup>
                </Form.Group> 
                <Form.Group> 
                    <Form.Radio
                        name="virtual_kitchen"
                        checked={ formValues.is_virtual_kitchen.value }
                        onChange={() => setStoreType({ 
                            ...formState, 
                            value: true,
                            valid: true
                        })}
                        label={"Virtual Kitchen"}
                    />
                    <Popup
                        trigger={
                            <InfoCircleFilled style={{color: "grey"}} className="text-black align-middle ml-2" />
                        }
                    >
                        online only restaurant
                    </Popup>
                </Form.Group>
            </Form.Field>
        </div>
    )
}

export default StoreAddress
