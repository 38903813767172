import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { Form } from 'semantic-ui-react';

import { CardNumberElement, CardExpiryElement, CardCVCElement, PostalCodeElement } from 'react-stripe-elements';

import { ReactComponent as VisaIcon } from '../../../images/VISA-CC_icon-1.svg';
import { ReactComponent as MastercardIcon } from '../../../images/Mastercard-CC_icon-1.svg';
import { ReactComponent as AmexIcon } from '../../../images/AM-CC_icon-1.svg';
import { ReactComponent as GenericIcon } from '../../../images/Generic-CC_icon-1.svg';

const StripeField = (props) => {
    const genericIcon = <GenericIcon />
    let [ccBrand, changeCCBrand] = useState(genericIcon)
    const {
        name,
        label,
        width,
        type,
        checkStripeFormCompletion
    } = props

    const cardBrandClass={
        'visa': < VisaIcon />,
        'mastercard': <MastercardIcon />,
        'amex': <AmexIcon />
    }
    
    const setBrandIcon = (brand) => {
        if (brand in cardBrandClass) {
            changeCCBrand(cardBrandClass[brand]);
        } else {
            changeCCBrand(genericIcon);
        }
    }
    
    const onFieldFocused = (e) => {
        ReactGA.event({
            category: 'Stripe',
            action: `${e.elementType} focused`
        });
    };

    const cardFieldChangeHandler = (e, type) => {
        checkStripeFormCompletion(e, type);
        setBrandIcon(e.brand);
    }

    const stripeDiv={
        'CCNum': <CardNumberElement name={ name } className='stripe-fields cc-field' placeholder="Credit Card Number" onChange={ (e) => cardFieldChangeHandler(e, 'ccNum') } onFocus={ (e) => onFieldFocused(e) } />,
        'CCExp': <CardExpiryElement name={ name } className='stripe-fields' placeholder="MM/YY" onChange={ (e) => checkStripeFormCompletion(e, 'ccExp') } onFocus={ (e) => onFieldFocused(e) } />,
        'CCCVC': <CardCVCElement name={ name } className='stripe-fields' placeholder="CVC"  onChange={ (e) => checkStripeFormCompletion(e, 'ccCvc') } onFocus={ (e) => onFieldFocused(e) } />,
        'CCZip': <PostalCodeElement name={ name } className='stripe-fields' placeholder="Zip Code" onChange={ (e) => checkStripeFormCompletion(e, 'ccZip') } onFocus={ (e) => onFieldFocused(e) } />
    };
    return (
        <Form.Field width={ width }>
        {/* <Fo className={fieldClassNames(touched, error, classes)}> */}
            <label style={ {whiteSpace: 'nowrap'} }>{ label }</label>
            {
                type === 'CCNum'
                ?
                <div style={ { display: 'flex' , alignItems: 'center'} }>
                    { stripeDiv[type] }
                    <span className="cc-icon">{ ccBrand }</span>
                </div>
                :
                stripeDiv[type]
            }
        </Form.Field>
    )
}

export default StripeField