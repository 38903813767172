import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';

const Button = (props) => {
    return (
        <SemanticButton 
            style={ { cursor: props.disabled ? 'default' : 'pointer', ...props.style } }
            className={ `button ${props.disabled ? 'disabled' : ''} ` + (props.className ? props.className : '') }
            onClick={ props.onClick }
            disabled={ props.disabled }
            loading={ props.loading }
        >
            { props.children }
        </SemanticButton>
    );
};

export default Button;