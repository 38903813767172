import React, { PureComponent } from 'react'

export default class error extends PureComponent {
    render() {
        return (
            <div className="ui centered grid">
                <div className="vert-center-parent">
                    <div>
                        Hey there, please make sure you’re accessing this page using your custom link in your getting started email. <br />
                        If the error persists, please contact your Online Ordering Expert or email <span className="underline"><a href="mailto:sales@ordermark.com">sales@ordermark.com</a></span> and we’ll provide you a new link right away!
                    </div>
                </div>
            </div>
        )
    }
}