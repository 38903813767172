import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { ReactComponent as HelpSVG } from '../../images/Have-a-question-1.svg';

const Help = (props) =>  {
    useEffect(() => {
        props.dispatch.companyModel.getCompanyDataAsync(props.app_id);
    }, [props.dispatch.companyModel, props.app_id]);

    return (
        <div style={ { textAlign: 'center' } }>
            <h1>
                We're here to help.
            </h1>
            <HelpSVG style={ {  height: '364px' } } />
            <div className="divider" />
            <h4 style={ { color: '#000' } }>
                If you have any questions let me know. 
            </h4>
            { 
                props.salesRep ?
                <p>
                    { props.salesRep } at <span className="underline">
                        <a href={ `mailto:${ props.salesRepresentativeEmail }` }>{ props.salesRepresentativeEmail }</a>
                    </span> or call { props.salesRepresentativePhone }
                </p>
                : null
            }
        </div>
    )

}

const mapStateToProps = (state, ownProps) => {
    const { name = '', email = '', phone = '' } = state.companyModel.sales_representative || {};

    return {
        app_id: ownProps.match.params.app_id,
        salesRep: name,
        salesRepresentativeEmail: email,
        salesRepresentativePhone: phone,
    }
}
export default connect(mapStateToProps)(Help);