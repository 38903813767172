import React, { useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import ProviderSegment from '../ui/provider-segment';
import providerLookup from '../../dummyData/providers';
import Loading from '../ui/loading';
import FormTextarea from '../forms/form-textarea';

const mapState = (state) => {
    return {
        reachModel: state.reachModel
    };
};

const reachSelectionReducer = (state, obj) => {
    return {
        ...state,
        ...obj
    }
}

const Reach = (props) => {
    const { reachModel, reachValues } = props;
    const { loaded, providers } = reachModel;

    const [reachSelections, setReachSelections] = useReducer(reachSelectionReducer, {});

    useEffect(() => {
        if (props.onFocus) {
            props.onFocus({
                target: {
                    placeholder: 'Reach'
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const reach_requests = Object.entries(reachSelections).reduce((memo, obj) => {
            if (obj[1].value === true) {
                memo.push(obj[0]);
            }

            return memo
        },[]);
        props.onChange(reach_requests);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reachSelections]);

    return (
        <div style={ { paddingTop: '20px' } }>
            {
                loaded ?
                    providers.length > 0 ?
                        <>
                            <div>
                                <h4 style={ { color: '#000', marginBottom: 0 } }>
                                    Let us help you find the right online ordering services for your restaurant.
                                </h4>
                                <p style={ { color: '#707070' } }>
                                    Here are some great options. Which would you like to get started with?
                                </p>
                            </div>
                        {
                            providers.map(provider => {
                                const providerMatch = providerLookup.find(
                                    pl => pl.value === provider
                                );
                                return (
                                    <ProviderSegment 
                                        key={ provider } 
                                        provider={ providerMatch ? providerMatch.text : provider }
                                        showCheckbox={ true }
                                        onChange={ setReachSelections }
                                        checked={ 
                                            (reachSelections[provider]
                                            && reachSelections[provider].value)
                                            || reachValues.includes(provider)
                                        }
                                    />
                                );
                            })
                        }
                        </>
                        :
                        <FormTextarea
                            onChange={ props.updateFormValues }
                            name="nss_notes"
                            placeholder="Any notes or special requests?"
                            value={ props.nss_notes }
                            label="Notes"
                        />
                        :
                        props.zipcode && props.zipcode.length >= 5 ?
                            <Loading />
                            :
                            <div className="reach-zip-required">
                                Please enter a zip code above to look up new services.
                            </div>
            }
        </div>
    );
};

export default connect(mapState)(Reach);
