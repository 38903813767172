import axios from 'axios'

let request;

if (process.env.REACT_APP_ENV) {
    switch (process.env.REACT_APP_ENV) {
        case 'development':
            request = axios.create({
                baseURL: 'http://localhost:8000/onboard'
            });
            break;
        case 'staging':
            request = axios.create({
                baseURL: 'https://api-staging.ordermark.com/onboard'
            });
            break;
        case 'production':
            request = axios.create({
                baseURL: 'https://api.ordermark.com/onboard'
            });
            break;
        default:
            console.error(`Invalid REACT_APP_ENV: ${process.env.REACT_APP_ENV}. Valid values are development, staging, and production.`)
    }
} else {
  console.error(
    `REACT_APP_ENV environment variable not passed in on compilation`
  );
}

export default request;
