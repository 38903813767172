import React from 'react';
import { RenderPolicyCheckbox } from '../forms'

const TermsOfService = (props) => {
    return (
        <div>
            <h4>No long-term contract, commitment, or hidden fees.</h4>
            <p style={ { fontStyle: 'italic', color: '#707070' } }>
                Pricing is per location. Each locations billing cycle begins 10 days after your equipment 
                is delivered OR when you process your first order, whichever comes first.
            </p>
            <div className="ui grid">
                <RenderPolicyCheckbox
                    checked={props.checked}
                    name={props.name}
                    changeHandler={props.changeHandler}
                />
                <div className="fourteen wide column tos-check-copy">
                    <div className="terms-policy">
                        I have read and agree to Ordermark’s&nbsp;
                        <a href="https://www.ordermark.com/rsa/" target="_blank" rel="noopener noreferrer">
                            Terms of Service.
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermsOfService
