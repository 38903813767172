import React from 'react'
import { Form, Checkbox } from 'semantic-ui-react'

const fieldClassNames = (isTouched, error, classes) => `${classes} field ${error && isTouched ? 'error' : ''}`
const renderError = (isTouched, error) => {
    if (isTouched && error) {
        return (
            <div className="error-text">
                <div>{error}</div>
            </div>
        )
    }
}

export const isEmpty = (obj) => {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const isFormValid = (formValues) => {
    return Object.values(formValues).filter(fv => fv.valid === false).length === 0;
};

export const renderSelector = ({ input, meta: { touched, error }, classes, arr, selection, dropdownType, label }) => {
    return (
        <div className={fieldClassNames(touched, error, classes)}>
            <label>{label}</label>
            <select {...input} className="ui selection dropdown">
                <option value="">{selection}</option>
                {
                    arr.map((val, idx, arr) => {                        
                        return (
                            <option value={val.value} key={val.value}>
                                {val.label}
                            </option>
                        )
                    })
                }
            </select>
            {renderError(touched, error)}
            {/* {touched && error && <span>{error}</span>} */}
        </div>
    )
}
export const setFormValues = (values = {}) => {
    return Object.entries(values).reduce((memo, obj) => {
        memo[obj[0]] = {
            value: obj[1]
        };
        return memo;
    }, {});
};
export const getFormValues = (values = {}) => {
    return Object.entries(values).reduce((memo, obj) => {
        memo[obj[0]] = obj[1].value;
        return memo;
    }, {});
};

export const RenderPolicyCheckbox = (props) => {
    return (
        <Checkbox
            checked={ props.checked }
            name={ props.name }
            label={ props.label }
            onChange={(e, { checked }) => props.changeHandler(!props.checked)}
            className="one wide column"
        />
    )
}

export const renderRadio = (field) => {
    return (
        <Form.Radio
            checked={field.input.value === field.radioValue}
            label={field.label}
            name={field.input.name}
            onChange={(e, { checked }) => field.input.onChange(field.radioValue)}
        />
    );
}

export const renderTextArea = field => {
    return (
        <Form.TextArea
            {...field.input}
            label={field.label}
            placeholder={field.placeholder}
        />
    )
}
