import React, { PureComponent } from 'react';
import { Form } from 'semantic-ui-react';

import { ReactComponent as StripeSticker } from '../../images/powered_by_stripe.svg';
import StripeField from '../forms/stripeForms/stripeField'

class CreditCardPaymentOption extends PureComponent {
    constructor(props) {
        super(props)
        
        this.state={
            ccNum: false,
            ccExp: false,
            ccCvc: false,
            ccZip: false,
        }
    }

    checkStripeFormCompletion = (e, form) => {
        this.setState(() => {
            return {
                [form]: e.complete
            }
        })
        const stripeVals = Object.values(this.state);
        const allValid = stripeVals.filter(val => val === false).length === 0;

        if (allValid) {
            this.props.setStripeValidity(true);
        } else {
            this.props.setStripeValidity(false);
        }
    }

    render() {
        return (
            <div className="ui grid">
                <Form className="cc-form-container">
                    <div className="row" style={{marginTop: '0', marginBottom: '1rem'}}>
                        <div className="fifteen wide centered column">
                            <div className="ui segment credit-card-segment">
                                <div>
                                    <StripeField 
                                        name= "billingCCNumber"
                                        label="Credit Card Number"
                                        type="CCNum"
                                        width="fifteen"
                                        checkStripeFormCompletion={this.checkStripeFormCompletion}
                                    />
                                    <Form.Group>
                                        <StripeField 
                                            name="billingCCExpiration"
                                            label="Expiration Date"
                                            type="CCExp"
                                            width="ten"
                                            checkStripeFormCompletion={this.checkStripeFormCompletion}
                                        />
                                        <StripeField 
                                            name="billingCCCVC"
                                            label="Security Code"
                                            type="CCCVC"
                                            width="four"
                                            checkStripeFormCompletion={this.checkStripeFormCompletion}
                                        />
                                    </Form.Group>
                                    
                                    <StripeField
                                        name="billingCCzip"
                                        label="Zip Code"
                                        type="CCZip"
                                        width="six"
                                        checkStripeFormCompletion={this.checkStripeFormCompletion}
                                    />
                                </div>
                                <div style={{marginTop: '10px'}}>
                                    <div>
                                        Secure Payment Processing
                                    </div>
                                    <div style={{width: "150px"}}>
                                        <StripeSticker />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        )
    }
}

export default CreditCardPaymentOption