import { createModel } from '@rematch/core';
import request from '../utils/request';

export const partnersModel = createModel({
    state: {
        providers: {},
        pos: {}
    },
    reducers: {
        loadPartners: (unused, payload) => {
            return payload;
        }
    },
    effects: {
        async getPartnersAsync() {
            // TODO: change to real URL once microservice is released
            request.get('https://api.ordermark.com/partners/providers/')
            .then(resp => {
                this.loadPartners({
                    providers: resp.data
                });
            });
        } 
    }
});
