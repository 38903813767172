import React, { PureComponent } from 'react'

export default class BillingInfo extends PureComponent {
    render() {
        if (!this.props.fixed_monthly_rate) { 
            return null;
        }

        return (
            <div className="billing-info">
                <div className="divider" />
                <h4 className="om-red" style={ { marginBottom: '1.7em' } }>Simple &ldquo;No Surprise&rdquo; Pricing.</h4>
                <div style={ { display: 'flex' } }>
                    <div style={ { textAlign: 'center', flex: 1 } }>
                        <h1 style={ { fontSize: '36px', marginBottom: '.2em' } }>
                            ${ this.props.fixed_monthly_rate > 0 ? this.props.fixed_monthly_rate : '0.00' }/Month
                        </h1>
                        <div style={ { fontSize: '14px', fontStyle: 'italic', color: '#707070' } }>
                            Per Location
                        </div>
                    </div>
                    {
                        this.props.setup_fee > 0 ?
                        <div style={ { textAlign: 'center', flex: 1 } }>
                            <h1 style={ { fontSize: '36px', marginBottom: '.2em' } }>
                                ${ this.props.setup_fee } POS Setup Fee
                            </h1>
                            <div style={ { fontSize: '14px', fontStyle: 'italic', color: '#707070' } }>
                                Per Location
                            </div>
                        </div>
                        : null
                    }
                </div>
                <div style={ { padding: '20px 0 5px 0', display: 'flex' } }>
                    <ul className="list-display list-checkmarks">
                        <li>Unlimited Orders</li>
                        <li>Unlimited Services</li>
                    </ul>
                    <ul className="list-display list-checkmarks">
                        <li>Hardware Included</li>
                        <li>24/7 Support</li>
                    </ul>
                </div>
            </div>
        )
    }
}
