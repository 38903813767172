const validate = (formValues) => {
    const errors={};

    for (let key in formValues) {
        if (!formValues[key]) {
            errors[key] = `please enter a(n) ${key}`
        }
    }

    return errors
}
export default validate

export const required = value => (value || typeof value === 'number' ? null : 'Required')
// const maxLength = max => value => value && value.length > max ? `Must be ${max} characters or less` : null
// const maxLength15 = maxLength(15)
export const minLength = min => value =>value && value.length < min ? `Must be ${min} characters or more` : null
export const minLength5 = minLength(5);
// const number = value => value && isNaN(Number(value)) ? 'Must be a number' : null
// const minValue = min => value => value && value < min ? `Must be at least ${min}` : null
// const minValue13 = minValue(13)
export const isEmail = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : null
// const tooYoung = value => value && value < 13 ? 'You do not meet the minimum age requirement!' : null
// const aol = value => value && /.+@aol\.com/.test(value) ? 'Really? You still use AOL for your email?': null
export const letters = value => value && /[^a-zA-Z ]/i.test(value) ? 'Only alphabetical characters' : null
export const phoneNumber = value => value && !/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i.test(value) ? 'Invalid phone number, must be 10 digits': null
export const zipCode = value => value && !/^(0|[1-9][0-9]{4})$/i.test(value) ? 'Invalid zip code, must be 5 digits': null
