import React, { useEffect } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';

import './scss/app.scss';
import history from './history';
import Routes from './routes';
import Error from "./routes/error";

history.listen((location, action) => {
    ReactGA.set({ page: location.pathname });
	  ReactGA.pageview(location.pathname);
})

let parsedUrl = new URL(window.location.href);

const App = (props) =>  {
    localStorage.clear();

    const loadFbPixel = () => {
      let fbScript = `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '149368115724561');
            fbq('track', 'PageView');
        `
        let scriptElm = document.createElement('script');
        scriptElm.type = 'text/javascript';
        scriptElm.innerHTML = fbScript;
        document.head.appendChild(scriptElm);

        const noscriptElm = document.createElement('noscript');
        const noScriptImg = document.createElement('img');
        noScriptImg.setAttribute('height', '1px');
        noScriptImg.setAttribute('width', '1px');
        noScriptImg.setAttribute('style', 'display:none');
        noScriptImg.setAttribute('src', 'https://www.facebook.com/tr?id=149368115724561&ev=PageView&noscript=1(44 B)https://www.facebook.com/tr?id=149368115724561&ev=PageView&noscript=1');
        noscriptElm.appendChild(noScriptImg);
        document.head.appendChild(noscriptElm);
    }

    if (parsedUrl.search) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
  
      if (!localStorage.getItem("app_id")) {
        window.localStorage.setItem("app_id", params["app_id"]);
      }
      if (!localStorage.getItem("opportunity_id")) {
        window.localStorage.setItem("opportunity_id", params["opportunity_id"]);
      }
    }

    useEffect(() => {
      const initialLoad = () => {
        const utm_qs = parsedUrl.searchParams.get("utm_source");
          if (utm_qs && utm_qs === 'fb') {
              loadFbPixel();
          }
      }

      initialLoad();
      }, []);
  
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route
            path="/start/:app_id"
            render={(props) => {
              return <Routes {...props} />;
            }}
          />
          <Route
            path="/start/*"
            render={(props) => {
              return <Routes {...props} />;
            }}
          />
          <Route
            path="/company/:app_id"
            render={(props) => {
              return <Routes {...props} />;
            }}
          />
          <Route
            path="/company/*"
            render={(props) => {
              return <Routes {...props} />;
            }}
          />
          <Route
            path="/payments/:app_id"
            render={(props) => {
              return <Routes {...props} />;
            }}
          />
          <Route
            exact
            path="/locations/:app_id"
            render={(props) => {
              return <Routes {...props} />;
            }}
          />
          <Route
            path="/locations/:app_id/store/new"
            render={(props) => {
              return <Routes {...props} />;
            }}
          />
          <Route
            path="/locations/:app_id/store/:store_id"
            render={(props) => {
              return <Routes {...props} />;
            }}
          />
          <Route
            exact
            path="/verify/:app_id"
            render={(props) => {
              return <Routes {...props} />;
            }}
          />
          <Route
            exact
            path="/thankyou/:app_id"
            render={(props) => {
              return <Routes {...props} />;
            }}
          />
          <Route
            path="/help/:app_id"
            render={(props) => {
              return <Routes {...props} />;
            }}
          />
          <Error />
        </Switch>
      </Router>
      )
    </div>
  );
}

export default App;