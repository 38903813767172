import React, { useState } from 'react'
import { Form } from 'semantic-ui-react'

const FormSelect = (props) => {
    const [validationMessage, setValidationMessage] = useState(null);
    const [touched, setTouched] = useState(false);

    const validate = (value) => {
        if (props.validate) {
            const validationMessage = 
                Array.isArray(props.validate) ?
                props.validate.map(func => func(value)).filter(el => el).join('. ')
                : props.validate(value);
            setValidationMessage(validationMessage);
            if (validationMessage) {
                return false;
            }
        }
        return true;
    };

    
    const onChange = (e, touch = true) => {
        const { name, value } = e.target;
        const valid = validate(value);
        props.onChange({ 
            [name]: {
                value: value || '',
                valid
            }
        });
        setTouched(touch);
    };

    const onFocus = () => {
        if (props.onFocus) {
            props.onFocus({
                target: {
                    placeholder: props.name
                }
            });
        }
    }

    return (
        <Form.Field width={ props.width } error={ validationMessage && touched ? true : false } style={ props.style }>
            <label>{ props.label }</label>
            <select 
                className="ui selection dropdown" 
                name={ props.name } 
                onChange={ onChange } 
                onFocus={ onFocus } 
                onBlur={ onChange }
                value={ props.value || '' }
            >
                <option value="">{ props.placeholder }</option>
                {
                    props.options.map(option => {                        
                        return (
                            <option value={ option.value } key={ option.value }>
                                { option.text }
                            </option>
                        )
                    })
                }
            </select>
            {
            validationMessage
            && touched 
            && <div className="error-text">
                { validationMessage }
            </div>
        }
        </Form.Field>
    )
}

export default FormSelect;