import React, { useState } from 'react'
import { Form } from 'semantic-ui-react'

const FormCheckbox = (props) => {
    const [validationMessage, setValidationMessage] = useState(null);
    const [touched, setTouched] = useState(false);

    const validate = (value) => {
        if (props.validate) {
            const validationMessage = 
                Array.isArray(props.validate) ?
                props.validate.map(func => func(value)).filter(el => el).join('. ')
                : props.validate(value);
            setValidationMessage(validationMessage);
            if (validationMessage) {
                return false;
            }
        }
        return true;
    }

    const onChange = (e, { checked }, touch = true) => {
        const valid = validate(checked);
        props.onChange({ 
            [props.name]: {
                value: checked || false,
                valid
            }
        });
        setTouched(touch);
    }

    return (
        <span>
            <Form.Checkbox
                checked={ props.value }
                name={ props.name }
                label={ props.label }
                onChange={ onChange }
                className={ props.className }
            />
            {
                validationMessage
                && touched 
                && <div className="error-text">
                    { validationMessage }
                </div>
            }
        </span>
        
    )
};

export default FormCheckbox;
