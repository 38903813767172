import { createModel } from '@rematch/core';
import request from '../utils/request'
import history from '../../history';

export const locationsModel = createModel({
    state: {
        locations: [],
        currentLocation: {},
  },
    reducers: {
        loadOpportunityData: (state, payload) => {
            return { ...state, ...payload };
        },
        loadLocationsData: (state, payload) => {
            return { ...state, locations: [...payload], loaded: true };
        },
        loadLocation: (state, payload) => {
            return { ...state, currentLocation: payload };
        },
        clearCurrentLocation: (state, payload) => {
            return { ...state, currentLocation: {} };
        },
        deleteLocation: (state, payload) => {
            const NewLocations = [...state.locations];
            const filteredLocations = NewLocations.filter(
              (store) => store.store_id !== payload.store_id
            );
            return { ...state, locations: filteredLocations };
        }
    },
    effects: () => ({
        async getAllLocations() {
            const app_id = localStorage.getItem("app_id");
            request
              .get(`/companies/${app_id}/stores/`)
              .then((resp) => {
                this.loadLocationsData(resp.data.locations);
              })
              .catch((e) => {
                console.log(e);
                history.push(`/error`);
              });
          },
        async getCurrentLocation(store_id) {
            const app_id = localStorage.getItem("app_id");
            !app_id
              ? history.push(`/error`)
              : request
                  .get(`/companies/${app_id}/stores/${store_id}/`)
                  .then((resp) => {
                    const {
                      contact,
                      address,
                      creds,
                      reach_requests,
                      cuisine_type,
                      is_virtual_kitchen,
                      base_menu,
                    } = resp.data;
                    const output = {
                      initialFormValues: {
                        ...address,
                        store_phone: address.phone,
                        store_name: address.name,
                        ...contact,
                        cuisine_type,
                        is_virtual_kitchen,
                      },
                      creds: creds || [],
                      reach_requests: reach_requests || [],
                      base_menu,
                    };
                    this.loadLocation(output);
                  })
                  .catch((e) => {
                    console.log(e);
                  });
          },
        async addLocationAsync() {
            const app_id = localStorage.getItem("app_id");
            this.clearCurrentLocation();
            history.push(`/locations/${app_id}/store/new`)
          },
        async saveLocationAsync(payload) {
          const {
            first_name,
            last_name,
            email,
            phone,
            store_name,
            address1,
            address2,
            city,
            state,
            zipcode,
            creds,
            cuisine_type,
            is_virtual_kitchen,
            reach_requests,
            store_phone,
            nss_notes,
            base_menu,
            country,
          } = payload.values;
          const dataToSend = {
            contact: {
              first_name,
              last_name,
              email,
              phone,
            },
            address: {
              name: store_name,
              address1,
              address2,
              city,
              state,
              zipcode,
              phone: store_phone,
              country,
            },
            cuisine_type,
            is_virtual_kitchen,
            creds,
            base_menu,
            creds_filled: payload.credsFilled,
            reach_requests,
            nss_notes,
          };
          const app_id = localStorage.getItem("app_id");
          const putUrl =
            payload.opportunity_id == null
              ? payload.store_id
                ? `/companies/${app_id}/stores/${payload.store_id}/`
                : `/companies/${app_id}/stores/`
              : payload.store_id
              ? `/opportunities/${payload.opportunity_id}/stores/${payload.store_id}/`
              : `/opportunities/${payload.opportunity_id}/stores/`;
          request
            .put(putUrl, dataToSend)
            .then((resp) => {
              if (payload.replaceUrl) {
                const store_id = resp.data.store_id;
                history.replace(`/locations/${app_id}/store/${store_id}`)
              } else {
                history.push(`/locations/${app_id}`)
              }
            })
            .catch((e) => {
              history.push(`/error`);
            });
        },
        async deleteLocationAsync(payload) {
          const app_id = localStorage.getItem("app_id");
          const { opportunity_id, store_id } = payload;
          const delURL =
            opportunity_id == null
              ? `/companies/${app_id}/stores/${store_id}/`
              : `/opportunities/${opportunity_id}/stores/${store_id}/`;
          request.delete(delURL).then((resp) => {
            this.deleteLocation({ store_id });
          })
        }
      })
})