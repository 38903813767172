import React from 'react'

import Plaid from './Plaid'
import { ReactComponent as ACHSvg } from '../../images/ACH_illustration-1.svg';


const ACHPaymentOption = (props) => {
    return (
        <div style={ {marginTop: '3rem', marginBottom: '1rem'} }>
            <div 
                className="ui segment inactive" 
                style={ {width: "100%", backgroundColor: "#F4F4F4", display: "flex", alignItems: "center", flexDirection: "column", padding: '10%'} } 
            >
                <ACHSvg style={{width: "150px"}}/>
                <Plaid
                    postPaymentDataAsync={ props.postPaymentDataAsync }
                    gaPaymentEvent = { props.gaPaymentEvent }
                    app_id={ props.app_id }
                    ACHToken={ props.ACHToken }
                    name={ props.name }
                    email={ props.email }
                />
            </div>
        </div>
    )
}


export default ACHPaymentOption;