import React, { useState } from 'react'
import PlaidLink from 'react-plaid-link'

import { PLAID_PUBLIC_KEY } from '../../config';

const Plaid = (props) => {
    const [publicToken, changePublicToken] = useState(props.ACHToken)

    const handleOnSuccess = (token, metadata) => {
        changePublicToken(token);

        const payload = {
            app_id: props.app_id,
            token_type: "ACH",
            token: metadata
        };
        props.gaPaymentEvent('Plaid', props.app_id);
        props.postPaymentDataAsync(payload);
    };

    let env = process.env.NODE_ENV === 'development' || window.location.hostname.includes('staging-2') ? 'sandbox' : 'production';

    return (
        <div
            key={publicToken}
        >
            <PlaidLink
                clientName="Ordermark"
                env={ `${env}` }
                product={ ["auth"] }
                user={{
                    legalName: props.name,
                    emailAddress: props.email,
                }}
                token={ publicToken }
                apiVersion={'v2'}
                publicKey={ PLAID_PUBLIC_KEY }
                onSuccess={ handleOnSuccess }
                webhook="https://api.ordermark.com/onboard/billing-webhook/"
                className="ACH-setup"
            >
                { props.ACHToken ? 'ACH COMPLETE' : 'Setup ACH' }
            </PlaidLink>
        </div>
    )
}

export default Plaid
