import { init } from '@rematch/core';
import thunk from 'redux-thunk';

import * as models from './models'

export default init({
    models,
    redux: {
        middlewares: [thunk],
        devtoolOptions: {
            disabled: process.env.NODE_ENV === 'production' ? true : false
        },
    }
})