import { createModel } from '@rematch/core';
import request from '../utils/request';

export const reachModel = createModel({
    state: {},
    reducers: {
        clearReachProviders: (state, payload) => {
            return { 
                ...state, 
                providers: [],
                loaded: false
            };
        },
        loadReachProviders: (state, payload) => {
            return { 
                ...state, 
                providers: payload ,
                loaded: true
            };
        }
    },
    effects: {
        async getReachProviders(payload) {
            const { zipcode } = payload;
            this.clearReachProviders();
            request.get(`/reach/${zipcode}/`)
            .then(resp => {
                this.loadReachProviders(resp.data.providers);
            })
            .catch(e => {
                console.log(e)
            })
        } 
    }
});
