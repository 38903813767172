import React, { useState } from 'react';
import { Image } from 'semantic-ui-react';

function normalizeProviderName(provider) {
    return provider.toLowerCase().replace(/[^a-z0-9]*/ig, '');
}
function getProviderIconURL(provider) {
    provider = normalizeProviderName(provider);
    return `https://s3.us-east-2.amazonaws.com/static.ordermark.com/provider-logos/${provider}.png`;
}

const ProviderIcon = ({ provider, imageSize = '48px' }) => {

    const [usePlaceholder, setUsePlaceholder] = useState(false);
    const image_url = provider ? getProviderIconURL(provider) : null;

    const handleError = () => {
        setUsePlaceholder(true);
    };

    return usePlaceholder || !image_url ?
        (
            <div
                style={ {height: imageSize, width: imageSize, lineHeight: imageSize } }
                className="provider-icon-placeholder f2"
                title={ provider.display_name }
            >
                { provider[0].toUpperCase() }
            </div>
        )
        :
        (
            <Image
                style={ {height: imageSize, width: imageSize } }
                className="provider-icon"
                title={ provider }
                circular
                src={ image_url }
                onError={ handleError }
            />
        );
};

export default ProviderIcon;
