import React from 'react';
import Header from './header'
import Footer from './footer';
import { ReactComponent as CheckmarkSVG } from '../../images/checkmark.svg';


const Page = (props) => {
    const StepCircle = ({ step }) => {
        if (!props.currentStep) { 
            return null;
        }
        let type = '';
        if (props.currentStep > step) {
            type = 'complete';
        } else if (props.currentStep === step) {
            type = 'current';
        }
        return(
            <div className={ 'step-circle ' + type }>
                { 
                    type === 'complete' &&
                    <CheckmarkSVG style={ { width: '16px', margin: '3px' } }/>
                }
            </div>
        );
    }

    const Stepper = () => {
        if (!props.currentStep) {
            return null;
        }

        return (
            <div className="page-stepper">
                <div className="page-stepper-inner">
                    <div className="page-stepper-list-container">
                        <ul>
                            <li><StepCircle step={ 1 } />Account</li>
                            <li><StepCircle step={ 2 } />Billing</li>
                            <li><StepCircle step={ 3 } />Stores</li>
                        </ul>
                    </div>
                <div className="page-stepper-bg-line" />
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="full-height-flex">
                <Header
                    headerCopy={props.headerCopy}
                    app_id={ props.children.props.match.params.app_id }
                    opportunity_id={ props.children.props.match.params.opportunity_id }
                />
                <div className="row stretched">
                    <div className="six wide column">
                        <div className="main-content-container">
                            <Stepper />
                            <div className="main-content">
                                { props.children }
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <Footer
                app_id={ props.children.props.match.params.app_id }
                opportunity_id={ props.children.props.match.params.opportunity_id }
            />
        </div>
    )
}

export default Page