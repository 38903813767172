import React from 'react';
import { ReactComponent as CheckmarkGreenSVG } from '../../images/checkmark-green.svg';


const ProviderFeaturesList = ({ provider }) => {

    const listedFeatures = ['pausing', 'eighty_six', 'publish'];

    if (!provider) {
        return <hr />;
    }

    const instantFeatures = listedFeatures.filter(feature => {
        return provider[feature] && provider[feature].default === 'auto';
    });

    // if no features are instant, don't bother drawing the box
    if (instantFeatures.length === 0) {
        return null;
    }

    return (
        <div className="provider-feature-list">
            <p>Instant Changes</p>
            <ul>
                {
                    listedFeatures.map(listedFeature => {
                        const feature = provider[listedFeature];
                        if (
                            feature.default &&
                            feature.default !== 'none'
                        ) {
                            return (
                                <li key={ feature.title }>
                                    <span className="checkmark-holder">
                                        {
                                            feature.default === 'auto' &&
                                            <CheckmarkGreenSVG style={ { width: '16px' } } />
                                        }
                                    </span>
                                    <span className="feature-name">
                                        { feature.title }
                                    </span>
                                </li>
                            );
                        }
                        return null;
                    })
                }
            </ul>
        </div>
    );
};

export default ProviderFeaturesList;
