const dummyData = [
    {
        "text": "DoorDash",
        "name": "doordash",
        "value": "doordash",
        "sf_value": "DoorDash"
    },
    {
        "text": "Postmates",
        "name": "postmates",
        "value": "postmates",
        "sf_value": "Postmates"
    },
    {
        "text": "Uber Eats",
        "name": "uber_eats",
        "value": "uber_eats",
        "sf_value": "Uber Eats"
    },
    {
        "text": "BtownMenus",
        "name": "btownmenus",
        "value": "btownmenus",
        "sf_value": "BtownMenus"
    },
    {
        "text": "Hungry Buffs",
        "name": "hungry_buffs",
        "value": "hungry_buffs",
        "sf_value": "Hungry Buffs"
    },
    {
        "text": "The TakeOut Bham",
        "name": "the_takeout_bham",
        "value": "the_takeout_bham",
        "sf_value": "The TakeOut Bham"
    },
    {
        "text": "Bring It To Me",
        "name": "bring_it_to_me",
        "value": "bring_it_to_me",
        "sf_value": "Bring It To Me"
    },
    {
        "text": "Caviar",
        "name": "caviar",
        "value": "caviar",
        "sf_value": "Caviar"
    },
    {
        "text": "2ndKitchen",
        "name": "2ndkitchen",
        "value": "2ndkitchen",
        "sf_value": "2ndKitchen"
    },
    {
        "text": "AggieFood",
        "name": "aggiefood",
        "value": "aggiefood",
        "sf_value": "AggieFood"
    },
    {
        "text": "Allset",
        "name": "allset",
        "value": "allset",
        "sf_value": "Allset"
    },
    {
        "text": "Blueraiderfood.com",
        "name": "blueraiderfood__com",
        "value": "blueraiderfood__com",
        "sf_value": "Blueraiderfood.com"
    },
    {
        "text": "Cafe Runner",
        "name": "cafe_runner",
        "value": "cafe_runner",
        "sf_value": "Cafe Runner"
    },
    {
        "text": "CHOMP",
        "name": "chomp",
        "value": "chomp",
        "sf_value": "CHOMP"
    },
    {
        "text": "Deliver Toledo",
        "name": "deliver_toledo",
        "value": "deliver_toledo",
        "sf_value": "Deliver Toledo"
    },
    {
        "text": "delivery.com",
        "name": "delivery__com",
        "value": "delivery__com",
        "sf_value": "delivery.com"
    },
    {
        "text": "EatStreet",
        "name": "eatstreet",
        "value": "eatstreet",
        "sf_value": "EatStreet"
    },
    {
        "text": "FetchMe Delivery",
        "name": "fetchme_delivery",
        "value": "fetchme_delivery",
        "sf_value": "FetchMe Delivery"
    },
    {
        "text": "FoodSquire",
        "name": "foodsquire",
        "value": "foodsquire",
        "sf_value": "FoodSquire"
    },
    {
        "text": "Hopper Maui",
        "name": "hopper_maui",
        "value": "hopper_maui",
        "sf_value": "Hopper Maui"
    },
    {
        "text": "Jolt Delivery",
        "name": "jolt_delivery",
        "value": "jolt_delivery",
        "sf_value": "Jolt Delivery"
    },
    {
        "text": "Lodel",
        "name": "lodel",
        "value": "lodel",
        "sf_value": "Lodel"
    },
    {
        "text": "Meal Runner",
        "name": "meal_runner",
        "value": "meal_runner",
        "sf_value": "Meal Runner"
    },
    {
        "text": "MenuRunners",
        "name": "menurunners",
        "value": "menurunners",
        "sf_value": "MenuRunners"
    },
    {
        "text": "Ritual",
        "name": "ritual",
        "value": "ritual",
        "sf_value": "Ritual"
    },
    {
        "text": "Slice",
        "name": "slice",
        "value": "slice",
        "sf_value": "Slice"
    },
    {
        "text": "Snackpass",
        "name": "snackpass",
        "value": "snackpass",
        "sf_value": "Snackpass"
    },
    {
        "text": "Texoma Delivery",
        "name": "texoma_delivery",
        "value": "texoma_delivery",
        "sf_value": "Texoma Delivery"
    },
    {
        "text": "EatnNow",
        "name": "eatnnow",
        "value": "eatnnow",
        "sf_value": "EatnNow"
    },
    {
        "text": "Cater Nation",
        "name": "cater_nation",
        "value": "cater_nation",
        "sf_value": "Cater Nation"
    }
]
export default dummyData;