import React, { useReducer, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';

import { isFormValid, setFormValues, getFormValues } from '../forms';
import Button from '../ui/button';
import FormSection from '../forms/form-section';
import Address from '../forms/address-forms/company-address-form';
import Contact from '../forms/contact-forms';

const formReducer = (state, obj) => {
    return {
        ...state,
        ...obj
    }
};

const onFieldFocused = (e) => {
    ReactGA.event({
        category: 'Company',
        action: `${e.target.placeholder} focused`
    });
};

let app_id = localStorage.getItem("app_id");

const Company = (props) => {

    const initialFormValues = setFormValues(props.companyVals) || {};
    const [formValues, updateFormValues] = useReducer(formReducer, initialFormValues);
    const clickHandler = () => {
        const parsedUrl = new URL(window.location.href);
        const referee_code = parsedUrl.searchParams.get("referee_code");
        app_id = localStorage.getItem("app_id");
        const payload = {
            app_id: app_id, 
            values: getFormValues(formValues),
            referee_code
        };
        props.dispatch.companyModel.postCompanyContactDataAsync(payload);
    };
    useEffect(() => {
        props.dispatch.companyModel.getCompanyDataAsync(app_id);
      },[props.dispatch.companyModel]);

    useEffect(() => {
        updateFormValues(setFormValues(props.companyVals));
    },[props.companyVals]);

    return (
        <div>
          <Form>
            <FormSection title="Contact Info">
              <Contact
                formValues={formValues}
                onChange={updateFormValues}
                onFocus={onFieldFocused}
              />
            </FormSection>
            <FormSection title="Company Info">
              <Address
                formValues={formValues}
                onChange={updateFormValues}
                onFocus={onFieldFocused}
              />
            </FormSection>
            <Form.Field>
              <Button disabled={!isFormValid(formValues)} onClick={clickHandler}>
                Next
              </Button>
            </Form.Field>
          </Form>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const companyVals = state.companyModel;

    return {
        app_id: ownProps.match.params.app_id,
        companyVals
    }
}
export default connect(mapStateToProps)(Company);