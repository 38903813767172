import { createModel } from '@rematch/core';
import request from '../utils/request'

export const billingModel = createModel({
    state: {
        ACHToken: null,
        ACHMetadata: null,
        ccToken: null,
        plaidPublicToken: null,
        token_type: null
    },

    reducers: {
        loadPaymentData: (state, payload) => {
            if (payload.token_type === 'Credit Card') {
                return { ...state, ccToken: payload.token, token_type: payload.token_type }
            } else {
                return { ...state, ACHToken: payload.public_token, token_type: payload.token_type }
            }
        },
        loadPlaidPublicToken: (state, payload) => {
            return { ...state, ACHToken: payload }
        }
    },
    effects: (dispatch) => ({
        async postPaymentDataAsync(payload) {
            const { app_id, token, token_type } = payload
            const billingReqBody = {
                token,
                token_type
            }
            request.put(`/payments/${app_id}/`, billingReqBody)
            .then(resp => {
                if (token_type === 'ACH') {
                    let payload = {
                        public_token: resp.data.token.public_token,
                        token_type: resp.data.token_type
                    }
                    this.loadPaymentData(payload);
                } else {
                    this.loadPaymentData(resp.data);
                }
            })
            .catch(e => {
                console.log(e)
            })
        },
        async getBillingDataAsync(app_id) {
            request.get(`/payments/${app_id}/`)
            .then(resp => {
                const payload = resp.data
                this.loadPaymentData(payload);
            })
            .catch(e => {
                console.log(e)
            })
        },
        async getPlaidDataAsync(app_id) {
            const params = {
                token_type: "ACH"
            }
            request.get(`/payments/${app_id}/`, { params })
            .then(resp => {
                const payload = resp.data.public_token
                this.loadPlaidPublicToken(payload);
            })
            .catch(e => {
                console.log(e)
            })
        },
        async verifyPlaidAsync(app_id) {
            const reqBody = {
                token_type: "ACH",
                plaid_verified: true
            }
            request.put(`/payments/${app_id}/`, reqBody)
            .then(resp => {
            })
            .catch(e => {
                console.log(e)
            })
        }
    })
})