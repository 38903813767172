import { createModel } from '@rematch/core';

export const uiModel = createModel({
    state: {
        headerCopy: ''
    },
    reducers: {
        changeHeaderCopy: (state, payload) => {
            return {...state, headerCopy: payload}
        }
    },
    effects: () => ({
        changeHeaderCopy(payload) {
            this.changeHeaderCopy(payload)
        }
    })
})