import React from 'react';
import Button from '../ui/button';
import { NavLink } from 'react-router-dom';
import { formatAddress } from '../ui/util';

export const AddLocationCard = ({ addLocation, deleteLocation, locations , opportunities, opportunity_id, company_submitted_to_sf }) => {
    const app_id = localStorage.getItem("app_id");
    const opportunity = opportunities?.find(opp => opp.opportunity_id === opportunity_id);
    const opportunitiy_submitted_to_sf = opportunity ? opportunity.submitted_to_salesforce : true;
    const addStoreButton = <Button className="button-hollow" onClick={ () => addLocation(app_id) }>Add a store</Button>;
    return (
      <div style={ { marginBottom: '65px' } }>
          <div className="locations-list-box">
              {
                 locations && locations.length !== 0 ?
                 null :
                  <div style={ { textAlign: 'left', padding: '20px' } }>
                      You haven't entered any stores yet. Please add a store.
                  </div>
              }
              {
                  locations && locations.map((location, idx) => {
                      const { address, store_id, submitted_to_salesforce } = location;
                      const deletePayload = {
                          app_id,
                          opportunity_id,
                          store_id
                      }

                      return (
                        <div className="locations-segment-divider" key={ idx }>
                            <div className="restaurant-name">
                                {
                                    address
                                    ?
                                    <div>
                                        <strong>
                                            { address.name }, 
                                            { 
                                                formatAddress({
                                                    city: address.city,
                                                    state: address.state,
                                                    postalCode: address.zipcode,
                                                    country: address.country
                                                })
                                            }
                                        </strong>
                                    </div>
                                    :
                                    <h3>store missing name</h3>
                                }
                            </div>
                            { 
                                !submitted_to_salesforce &&
                                <div className="locations-actions">
                                    <NavLink className="locations-action" to={ `/locations/${app_id}/store/${store_id}` }>Edit</NavLink>
                                    <div className="locations-action" onClick={() => deleteLocation(deletePayload)}>Delete</div>
                                </div>
                            }
                        </div>
                    )
                })
            }
          </div>
             <div>
             {opportunities?.length > 0
                ? !opportunitiy_submitted_to_sf && addStoreButton
                : !company_submitted_to_sf && addStoreButton}
            </div>
        </div>
      );
  };

export default AddLocationCard