/* eslint-disable */
String.prototype.padStart =
    String.prototype.padStart ?
    String.prototype.padStart
    : function(targetLength, padString) {
    targetLength = Math.floor(targetLength) || 0;
    if (targetLength < this.length) { return String(this) };

    padString = padString ? String(padString) : ' ';

    var pad = '';
    var len = targetLength - this.length;
    var i = 0;
    while (pad.length < len) {
        if (!padString[i]) {
            i = 0;
        }
        pad += padString[i];
        i++;
    }

    return pad + String(this).slice(0);
};

((E, d, w) => {
    if (!E.composedPath) {
        E.composedPath = function() {
        if (this.path) {
            return this.path;
        }
        var target = this.target;

        this.path = [];
        while (target.parentNode !== null) {
            this.path.push(target);
            target = target.parentNode;
        }
        this.path.push(d, w);
        return this.path;
        }
    }
    })(Event.prototype, document, window);

if (!Object.entries) {
    Object.entries = (obj) => {
        var ownProps = Object.keys(obj);
        var i = ownProps.length;
        var resArray = new Array(i); // preallocate the Array
        while (i--) {
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        }

        return resArray;
    };
}

if (!Object.values) {
    Object.values = (o) => Object.keys(o).map(k => o[k]);
}

if (!String.prototype.includes) {
    String.prototype.includes = function(search, start) {
      'use strict';
      if (typeof start !== 'number') {
        start = 0;
      }
  
      if (start + search.length > this.length) {
        return false;
      } else {
        return this.indexOf(search, start) !== -1;
      }
    };
}
 
if (!Array.prototype.includes) {
    Object.defineProperty(Array.prototype, "includes", {
      enumerable: false,
      value: function(obj) {
          var newArr = this.filter(function(el) {
            return el == obj;
          });
          return newArr.length > 0;
        }
    });
}
