import React, { useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Popup, Icon } from 'semantic-ui-react';
import request from '../utils/request'

import FormSelect from '../forms/form-select';
import FormField from '../forms/form-field';
import Button from '../ui/button'
import { isFormValid, getFormValues } from '../forms'
import { required } from '../forms/validate';
import ProviderFeaturesList from './provider-features-list';

export const AddServiceActiveKey = 'add_service';

// Only need the 'pin' login field if provider is UberEats
// const needPinField = (providerName)  => {
//     return providerName && providerName.toLowerCase().includes('uber');
// };

// Only need the 'vanityURL' login field if provider in list
const needVanityURL = (providerName)  => {
    return providerName && ['postmates'].includes(providerName.toLowerCase());
};

const AddServiceCard = (props) => {
    const [credsErrorShown, setCredsErrorShown] = useState(false);
    const [vanityURLErrorShown, setVanityURLErrorShown] = useState(false);
    
    const formReducer = (state, obj) => {
        if (obj.provider || obj.provider === '') {
            const newState = {
                provider: {
                    value: obj.provider.value, valid: true
                },
                username: {
                    value: '', valid: false
                },
                password: {
                    value: '', valid: false
                }
            };

            if (obj.provider.value === 'ubereats') {
                newState.username = {
                    value: null, valid: true
                };
                newState.password = {
                    value: null, valid: true
                }
            }

            // if (needPinField(obj.provider.value)) {
            //     newState.pin = {
            //         value: '', valid: false
            //     };
            // }

            if (needVanityURL(obj.provider.value)) {
                newState.vanityUrl = {
                    value: '', valid: false
                };
            }
            setCredsErrorShown(false);
            return newState;
        } else {
            return {
                ...state,
                ...obj
            };
        }
    };

    const [formValues, updateFormValues] = useReducer(formReducer, {});
    const [buttonLoading, setButtonLoading] = useState(false);
    
    const { creds = [], company = {} } = props;

    const onSubmit = () => {
        const enteredCreds = getFormValues(formValues);

        // hack to make it seem like UberEats was saved, but only shows on browser
        if (enteredCreds.provider === 'ubereats') {
            const newCreds = [...creds];
            newCreds.push(enteredCreds);
            props.onChange(newCreds);
            updateFormValues({ provider: '' });
            setCredsErrorShown(false);
            setVanityURLErrorShown(false);
            setButtonLoading(false);
            return;
        }

        const validate = ['doordash', 'postmates'].includes(enteredCreds.provider);
        const payload = {
            app_id: props.storeId,
            oos: enteredCreds.provider,
            username: enteredCreds.username,
            password: enteredCreds.password,
            pin: enteredCreds.pin,
            vanity_url: enteredCreds.vanityUrl,
            address_street: props.address.address1,
            address_city: props.address.city,
            address_state: props.address.state,
            address_zip: props.address.zipcode,
            validate,
            live_account: false // false for new customers, true for existing customers
        };

        if (enteredCreds.provider === 'ubereats') {
            delete payload.username;
            delete payload.password;
        }

        setButtonLoading(true);
        request.put(`https://api.ordermark.com/onboard/stores/${props.storeId}/creds/`, payload)
        .then(resp => {
            const newCreds = [...creds];
            newCreds.push(getFormValues(formValues));
            props.onChange(newCreds);
            updateFormValues({ provider: '' });
            setCredsErrorShown(false);
            setVanityURLErrorShown(false);
            setButtonLoading(false);
        }).catch(resp => {
            setCredsErrorShown(!resp.response.data.is_valid);
            setVanityURLErrorShown(resp.response.data.successful_uuid_retrieval === false);
            setButtonLoading(false);
        });;

    };

    const filteredProviders = () => {
        let fp = Object.entries(props.providers).reduce((memo, provider) => {
            if (provider[1].onboarding_hidden !== true) {
                memo.push({
                    text: provider[1].display_name,
                    value: provider[0]
                });
            }
            return memo;
        }, []);

        // uncomment to restrict multiple of same provider
        // fp = allProvidersList.filter(provider => !creds.find(cred => cred.provider === provider.value));

        // only show the big 4 for POS customers
        if (company.record_type === '012f4000001MqaXAAS') {
            fp = fp.filter(provider => ['doordash', 'postmates', 'ubereats', 'grubhub'].indexOf(provider.value) !== -1);
        }

        return fp;
    };

    const providerMatch = props.providers[formValues.provider && formValues.provider.value];

    return (
        <div className="card-box">
            <h3 className={ { marginBottom: 0 } }>Connect a Service</h3>
            <div>
                <div>
                    Enter your login credentials for each online ordering service at this location.
                </div>
                <div className="divider"></div>
                <FormSelect
                    value={ formValues.provider && formValues.provider.value }
                    name="provider"
                    onChange={ updateFormValues }
                    placeholder="Select a Provider"
                    options={ filteredProviders() }
                    width="ten"
                />
                { (formValues.provider && formValues.provider.value)
                    &&
                    <>
                        <ProviderFeaturesList
                            provider={ providerMatch }
                        />
                        {
                            formValues.provider.value !== 'ubereats' &&
                            <>
                                <div className="form-label">
                                    Enter your { providerMatch ? providerMatch.display_name : formValues.provider.value } login information below
                                </div>
                                <FormField
                                    label="Username"
                                    placeholder="username"
                                    name="username"
                                    validate={ required }
                                    value={ formValues.username && formValues.username.value }
                                    onChange={ updateFormValues }
                                />
                                <FormField
                                    label="Password"
                                    placeholder="password"
                                    name="password"
                                    type="password"
                                    validate={ required }
                                    value={ formValues.password && formValues.password.value }
                                    onChange={ updateFormValues }
                                />
                            </>
                        }           
                        
                        {
                            needVanityURL(formValues.provider.value) &&
                            <FormField
                                label={ 
                                    <div>
                                        Vanity URL
                                        <Popup
                                            trigger={ <Icon name="info circle" /> }
                                            content="A vanity url is the provider url where consumers can find your restaurant. It is unique per location."
                                            inverted
                                        />
                                    </div>
                                }
                                name="vanityUrl"
                                validate={ required }
                                placeholder="vanity URL"
                                value={ formValues.vanityUrl && formValues.vanityUrl.value }
                                onChange={ updateFormValues }
                            />
                        }
                        {
                            formValues.provider.value === 'ubereats' &&
                            <div style={{backgroundColor:'rgba(254, 243, 199)', padding:'1rem', fontSize: '16px', marginBottom: '1rem'}}>
                                Note: Once your Ordermark hardware is live you will need to activate your Uber Eats integration to start receiving orders through your Ordermark system.
                            </div>
                        }
                        {/* {
                            needPinField(formValues.provider.value) &&
                            <FormField
                                label="PIN"
                                name="pin"
                                placeholder="pin"
                                validate={ required }
                                value={ formValues.pin && formValues.pin.value }
                                onChange={ updateFormValues }
                                width="four"
                            />
                        } */}
                        {
                            credsErrorShown &&
                            <div className="creds-error">
                                <div>
                                    <div className="creds-error-icon"></div>
                                </div>
                                <div className="creds-error-message">
                                    {
                                        credsErrorShown &&
                                        <span data-testid="creds-error">
                                            { `Oops, your ${providerMatch.display_name} login was invalid.` }
                                        </span>
                                    }
                                    {
                                        vanityURLErrorShown &&
                                        <span data-testid="vanity-url-error">
                                            { ` Your ${providerMatch.display_name} vanity URL was invalid.` }
                                        </span>
                                    }
                                    { ' Please try again.' }
                                </div>
                            </div>
                        }
                    </>
                }
                <Form.Field>
                    <Button disabled={ !isFormValid(formValues) } onClick={ onSubmit } loading={ buttonLoading }>Save</Button>
                </Form.Field>
            </div>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    return {
        company: state.companyModel,
        providers: state.partnersModel.providers
    }
}

export default connect(mapStateToProps)(AddServiceCard);
