import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Button from '../ui/button';
import AddLocationCard from './add-location-card';

let app_id = localStorage.getItem("app_id");
let opportunity_id = localStorage.getItem("opportunity_id");

const Locations = (props) => {

  opportunity_id = localStorage.getItem("opportunity_id");
  app_id = localStorage.getItem("app_id");
  const { dispatch, locations, loaded, opportunities, submitted_to_salesforce } = props;
    const payload = {
      app_id,
      opportunity_id,
    };
    useEffect(() => {
        props.dispatch.locationsModel.getAllLocations();
        props.dispatch.companyModel.getCompanyDataAsync(app_id)
    }, [props.dispatch.companyModel, props.dispatch.locationsModel]);

    if (!loaded) {
      return null;
    }

    return (
      <div>
        <h3>Your Stores</h3>
        <AddLocationCard
          addLocation={dispatch.locationsModel.addLocationAsync}
          deleteLocation={dispatch.locationsModel.deleteLocationAsync}
          locations={locations}
          opportunities = {opportunities}
          opportunity_id={opportunity_id}
          company_submitted_to_sf={submitted_to_salesforce}
        />
        <div className="divider card-divider"></div>
          <Button
            disabled={locations.length === 0}
            onClick={() => dispatch.companyModel.completeFlowAsync(payload)}
            className="button-completion"
          >
            Complete
          </Button>
      </div>
    );
  };

const mapStateToProps = (state, ownProps) => {
    let { opportunities, submitted_to_salesforce } = state.companyModel;
    let { locations, loaded } = state.locationsModel
    return {
        locations,
        loaded,
        opportunities,
        opportunity_id: ownProps.match.params.opportunity_id,
        submitted_to_salesforce,
    }
}

export default connect(mapStateToProps)(Locations);