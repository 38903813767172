import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import {StripeProvider} from 'react-stripe-elements';

import './modules/utils/polyfills';

import * as serviceWorker from './serviceWorker';

import App from './App';
import store from './store';
import { GOOGLE_ANALYTICS_TRACKING_CODE, STRIPE_API_KEY, STRIPE_API_KEY_DEV  } from './config';

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_CODE);


const stripeAPI = process.env.NODE_ENV === 'development' || window.location.hostname.includes('staging-2') ? STRIPE_API_KEY_DEV : STRIPE_API_KEY

ReactDOM.render(
    <Provider store={store}>
        <StripeProvider apiKey={stripeAPI}>
            <App />
        </StripeProvider>
    </Provider>, 
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
