import React, { useState, useEffect } from 'react';
import { Accordion, } from 'semantic-ui-react';
import FormCheckbox from '../forms/form-checkbox';

const DropDownCard = ({ icon, label, isActive, setActive, provider, showCheckbox, onChange, checked, children }) => {

    const [active, setLocalActive] = useState(isActive);
    const onClick = () => {
        const newActive = !active;
        setLocalActive(newActive);
        setActive(newActive);
    };

    useEffect(() => {
        setLocalActive(isActive);
    }, [isActive]);

    return (
        <Accordion className="dropdown-card">
            <Accordion.Title
                className="dropdown-card-header"
                active={ active }
                onClick={ setActive ? onClick : null}
            >
                <div>
                    { icon }
                </div>
                <div className="dropdown-card-title">{ label }</div>
                {
                    showCheckbox
                    ?
                    <div className="ui grid">
                        <FormCheckbox
                            name={ provider.toLowerCase() }
                            onChange={ onChange }
                            value={ checked }
                        />
                    </div>
                    :
                    null
                }
            </Accordion.Title>
            <Accordion.Content active={ active }>
                { children }
            </Accordion.Content>
        </Accordion>
    );
};

export default DropDownCard;